import React from 'react';
import { SparklesIcon } from '@heroicons/react/24/outline';

interface WorksheetTipProps {
  tipContent?: string;
}

const WorksheetTip: React.FC<WorksheetTipProps> = ({ tipContent }) => {
  return (
    <div className="border-l-2 rounded-md border-zinc-500 text-black p-4 mt-4" role="alert" style={{ borderWidth: '1px', borderLeftWidth: '4px' }}>
      <div className="flex items-center">
        <SparklesIcon className="h-5 w-5 text-spring-leaves-600 mr-2" aria-hidden="true" />
        <span className="font-medium text-sm">Berni Tip</span>
      </div>
      <p className="ml-7 text-sm text-left mt-2">
        {tipContent}
      </p>
    </div>
  );
};

export default WorksheetTip;
