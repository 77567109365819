import React from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

interface WorksheetHeaderProps {
    title?: string; // Title is optional
    name?: string; // Name is optional
}

const WorksheetHeader: React.FC<WorksheetHeaderProps> = ({ title, name }) => {
  return (
    <div>
      <div className="mb-6 flex justify-center items-center print:flex print:justify-start print:items-center print:text-lg">
        <PencilSquareIcon className="h-6 w-6 text-black mr-2 print:h-5 print:w-5" aria-hidden="true" />
        <span className="print:text-left">{title}</span>
        {/* <span className='ml-2 text-xs print:text-sm'>v .1</span> */}
      </div>

      <div className="flex justify-between items-center ">
        <div>
          {name ? (
            <span className='text-md'>Name: <span className='text-md ml-1 font-semibold'>{name}</span></span>
          ) : (
            <>
              <span className='text-md'>Name:</span>
              <span className="border-b border-1 border-black ml-1" style={{ height: '1px', width: '200px', display: 'inline-block' }}></span>
            </>
          )}
        </div>
        <div>
          <span className='text-md mr-20'>Score:</span>
          {/* Placeholder for score if needed */}
        </div>
      </div>
    </div>
  );
};

export default WorksheetHeader;
