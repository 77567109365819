import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, BellIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import kueLogo from '../../assets/Berni-3.png';

function classNames(...classes: (string | undefined | null | boolean)[]) {
    return classes.filter(Boolean).join(' ');
}

export default function TeacherNavbar() {
  return (
    <Disclosure as="nav" className="bg-white shadow-md"> 
      {({ open }) => (
        <>
          <div className="mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex items-center">
                <Link to="/teacher-home">
                  <img
                    className="p-1 h-16 mt-6 mb-6 w-auto ml-2"
                    src={kueLogo}
                    alt="Berni AI Logo"
                  />
                </Link>
              </div>
              <div className="hidden sm:flex sm:space-x-4">
              
              </div>
              <div className="flex items-center space-x-4">
                <button className="text-gray-600 hover:text-gray-800">
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <Link 
                  to="/log-in" 
                  className="text-sm px-4 py-2 font-medium text-spring-leaves-900 border border-spring-leaves-400 rounded-md hover:bg-spring-leaves-200 hover:text-black">
                  Log out
                </Link>
                <div className="sm:hidden ml-3">
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-black hover:bg-spring-leaves-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-spring-leaves-300">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden bg-white">
            <div className="space-y-1 px-2 pb-3 pt-2">
              
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
