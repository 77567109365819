import { CheckIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';


const plans = [
  {
    title: 'Free',
    description: 'Get started with Berni AI with limited features.',
    yearlyPrice: '$0',
    monthlyPrice: null,
    buttonText: 'Get Started',
    features: [
      'AI-crafted printable worksheets',
      'AI-generated learning plan for students'
    ]
  },
  {
    title: 'Basic Individual',
    description: 'Unlock enhanced features for a comprehensive individual learning experience.',
    yearlyPrice: '$109', // updated yearly price
    monthlyPrice: '$10.99', // updated monthly price
    buttonText: 'Subscribe Now',
    features: [
      'Everything in Free Tier',
      'Adaptive AI-crafted worksheets',
      'Real-time progress dashboard',
      'Gamified learning elements',
      'Feedback mechanism',
      'Interactive Quizzes',
      'Offline Mode',
      'Access to a database of shared AI-created worksheets and learning plans'
    ]
  },
  {
    title: 'Premium Individual',
    description: 'For educators who want to maximize the potential of Berni AI for their students.',
    yearlyPrice: '$199', // updated yearly price
    monthlyPrice: '$19.99', // updated monthly price
    buttonText: 'Subscribe Now',
    features: [
      'Everything in Basic Tier',
      'Parental Insights Dashboard',
      'Goal Setting & Tracking for students',
      'Personalized learning paths',
      'Advanced Analytics',
      'Customization options for worksheets',
      'Priority support',
      'Progress update Notification system'
    ]
  },
  {
    title: 'Enterprise',
    description: 'For institutions and organizations. All features unlocked.',
    yearlyPrice: 'Contact Us', // stays the same
    monthlyPrice: '', // added a starting price for better clarity
    buttonText: 'Contact Sales',
    features: [
      'All features from Premium Individual tier',
      'Data security & GDPR compliance',
      'Dedicated Account Manager',
      'Bulk User Management',
      'Custom Branding',
      '24/7 Support',
      'Training & Webinars'
    ]
  }
];

export default function Pricing() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-spring-leaves-900 sm:text-4xl">Choose Your Plan</h2>
          <p className="mt-6 text-lg leading-8 text-spring-leaves-600">
            Experience the future of education with Berni AI. Find a plan that's right for you.
          </p>
        </div>
        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-3 sm:gap-12">
          {plans.map((plan) => (
            <div key={plan.title} className="rounded-3xl ring-1 ring-spring-leaves-200 p-8 sm:p-10 flex flex-col">
              <div className="flex-1">
                <h3 className="text-2xl font-bold tracking-tight text-spring-leaves-900">{plan.title}</h3>
                <p className="mt-6 text-base leading-7 text-spring-leaves-600">{plan.description}</p>
                <div className="mt-10 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-spring-leaves-600">What’s included</h4>
                  <div className="h-px flex-auto bg-spring-leaves-100" />
                </div>
                <ul
                  role="list"
                  className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-spring-leaves-600"
                >
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon className="h-6 w-5 flex-none text-spring-leaves-600" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 text-center">
                <p className="flex items-baseline justify-center gap-x-2">
                  <span className="text-3xl font-bold tracking-tight text-spring-leaves-900">{plan.yearlyPrice}</span>
                  {plan.monthlyPrice && (
                    <span className="text-sm font-semibold leading-6 tracking-wide text-spring-leaves-600">/{plan.monthlyPrice} per month</span>
                  )}
                </p>
                <Link
                  to={plan.title === 'Enterprise' ? '/contact' : '/join'}
                  className="mt-6 block w-full rounded-md bg-spring-leaves-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-spring-leaves-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-spring-leaves-600"
                >
                  {plan.buttonText}
                </Link>
                <p className="mt-6 text-xs leading-5 text-spring-leaves-600">
                  Secure payment options available. Cancel anytime.
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}