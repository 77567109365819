import React from 'react';
import { AcademicCapIcon, ChartBarIcon, PuzzlePieceIcon, UserGroupIcon, BoltIcon, HeartIcon } from '@heroicons/react/24/outline';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


const featuresForTeachers = [
  {
    icon: <AcademicCapIcon className="h-6 w-6 text-spring-leaves-700" />,
    title: 'Dynamic Worksheet Generation',
    description: 'Berni AI allows educators to create personalized, printable worksheets using AI, tailored to each student\'s learning level and progress.',
  },
  {
    icon: <ChartBarIcon className="h-6 w-6 text-spring-leaves-700" />,
    title: 'Performance Analytics',
    description: 'Teachers can access detailed analytics to track and analyze each student\'s performance, helping to identify strengths, weaknesses, and areas needing additional focus.',
  },
  {
    icon: <PuzzlePieceIcon className="h-6 w-6 text-spring-leaves-700" />,
    title: 'Resource Sharing Community',
    description: 'A platform feature that enables teachers to share, access, and collaborate on educational resources, worksheets, and teaching strategies with other educators.',
  },
];

const featuresForParents = [
  {
    icon: <UserGroupIcon className="h-6 w-6 text-spring-leaves-700" />,
    title: 'Progress Tracking',
    description: 'Parents can log into Berni AI to see detailed reports on their child’s academic performance, including strengths, areas for improvement, and overall progress.',
  },
  {
    icon: <BoltIcon className="h-6 w-6 text-spring-leaves-700" />,
    title: 'Custom Homework Creation',
    description: 'The platform allows parents to generate homework worksheets tailored to the specific areas where their child might be struggling, supporting targeted learning at home.',
  },
  {
    icon: <HeartIcon className="h-6 w-6 text-spring-leaves-700" />,
    title: 'Comparative Analytics',
    description: 'Berni AI provides insights on how a child\'s performance compares with state or national averages, offering a broader context to the child\'s academic standing.',
  },
];

interface FeatureItemProps {
  icon: JSX.Element;
  title: string;
  description: string;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ icon, title, description }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <motion.div
      ref={ref}
      variants={itemVariants}
      initial="hidden"
      animate={controls}
      className="flex flex-col bg-zinc-100 items-center p-4 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
    >
      <div className="p-2 rounded-full bg-spring-leaves-200 mb-3">
        {icon}
      </div>
      <h3 className="mb-2 text-lg font-semibold text-spring-leaves-800">{title}</h3>
      <p className="text-sm text-spring-leaves-600 text-center">{description}</p>
    </motion.div>
  );
};

const Features: React.FC = () => {
  return (
    <div className="py-12 px-4 border-y-2 bg-gradient-to-r from-spring-leaves-300 to-spring-leaves-600 border-natural-200">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-xl font-semibold text-center text-white mb-8">For Educators</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
          {featuresForTeachers.map((feature, index) => (
            <FeatureItem key={index} {...feature} />
          ))}
        </div>
        <h2 className="text-xl font-semibold text-center text-white mb-8">For Guardians</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {featuresForParents.map((feature, index) => (
            <FeatureItem key={index} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;