import React from 'react';

// Define a type for the QuickOptionBadge props
interface QuickOptionBadgeProps {
  label: string;
  bgColor: string;
  textColor: string;
  ringColor: string;
  onClick: () => void;
}

function QuickOptionBadge({ label, bgColor, textColor, ringColor, onClick }: QuickOptionBadgeProps) {
  return (
    <button
      className={`inline-flex items-center rounded-lg ${bgColor} px-2 py-1 text-xs font-normal ${textColor} ring-1 ring-inset ${ringColor} transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-md mb-4 mt-2`}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

export default function QuickOptionBadges() {
  const handleBadgeClick = (label: string) => {
    console.log(`Clicked: ${label}`);
    // Implement the logic to autofill the form based on the badge clicked
  };

  return (
    <>
      <div className='flex gap-3 justify-center'>
        <QuickOptionBadge label="Multiplication" bgColor="bg-gray-50" textColor="text-gray-600" ringColor="ring-gray-500/10" onClick={() => handleBadgeClick("Multiplication")} />
        <QuickOptionBadge label="Division" bgColor="bg-red-50" textColor="text-red-700" ringColor="ring-red-600/10" onClick={() => handleBadgeClick("Division")} />
        <QuickOptionBadge label="Fractions" bgColor="bg-yellow-50" textColor="text-yellow-800" ringColor="ring-yellow-600/20" onClick={() => handleBadgeClick("Fractions")} />
        <QuickOptionBadge label="Addition" bgColor="bg-green-50" textColor="text-green-700" ringColor="ring-green-600/20" onClick={() => handleBadgeClick("Addition")} />
        <QuickOptionBadge label="Subtraction" bgColor="bg-blue-50" textColor="text-blue-700" ringColor="ring-blue-700/10" onClick={() => handleBadgeClick("Subtraction")} />
      </div>
      <hr className="border-1 border-gray-300 my-2" />
    </>
  );
}
