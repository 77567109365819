import React from 'react';
import ApexCharts from 'react-apexcharts';
import {ChartPieIcon } from '@heroicons/react/24/solid';

const shadowStyle: React.CSSProperties = {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.10), 0px 8px 16px rgba(0, 0, 0, 0.08)'
};

// Mock data for the pie chart
const mockPieData = [
    { label: 'Numerical Addition', value: 20 }, // e.g., 20% of the time spent on Numerical Addition
    { label: 'Word Math Problems', value: 15 }, // e.g., 15% of the time spent on Word Math Problems
    { label: 'Division', value: 25 }, // e.g., 25% of the time spent on Long Division
    { label: 'Basic Geometry', value: 15 }, // e.g., 5% of the time spent on Basic Geometry
    { label: 'Introductory Fractions', value: 25 }, // e.g., 5% of the time spent on Introductory Fractions
  ];
  

const TeacherPieChart: React.FC = () => {
  const totalValue = mockPieData.reduce((sum, data) => sum + data.value, 0);

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      width: 380,
      type: 'pie', // Specify the exact string literal expected for pie charts
    },
    colors: [
        '#71967c', // spring-leaves-400
        '#588466', // spring-leaves-500
        '#3c5f48', // spring-leaves-600
        '#304c3a', // spring-leaves-700
        '#283d30', // spring-leaves-800
      ],
    labels: mockPieData.map(data => data.label),
    responsive: [
        {
          breakpoint: 768, // for tablets and large mobile devices
          options: {
            chart: {
              width: '100%', // Use percentage for fluid resizing
            },
            legend: {
              position: 'bottom'
            }
          }
        },
        {
          breakpoint: 480, // for small mobile devices
          options: {
            chart: {
              width: '100%', // Use percentage for fluid resizing
            },
            legend: {
              position: 'bottom',
              fontSize: '10px', // Smaller legend text
            },
            dataLabels: {
              style: {
                fontSize: '10px', // Smaller labels within the pie
              },
            },
          }
        }
      ]
  };

  const chartSeries = mockPieData.map(data => data.value);

  return (
    <div className="max-w-lg w-full bg-neutral-100 rounded-lg shadow p-4 md:p-6" style={shadowStyle}>
      <div className="flex justify-between pb-4 mb-4 border-b border-gray-200">
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-lg bg-spring-leaves-200 flex items-center justify-center mr-3">
            <ChartPieIcon className="h-6 w-6 text-spring-leaves-800" />
          </div>
          <div>
            <h5 className="leading-none text-xl font-bold text-gray-900 pb-1">Curriculum Breakdown</h5>
          </div>
        </div>
      </div>

      <div id="pie-chart">
        <ApexCharts
          options={chartOptions}
          series={chartSeries}
          type="pie"
          width={450}
          height={450}
        />
      </div>

      <div className="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between mt-24">
        <div className="pt-5">      
          <a href="/detailed-report" className="text-spring-leaves-500 hover:text-spring-leaves-700">View Detailed Report</a>
        </div>
      </div>
    </div>
  );
};

export default TeacherPieChart;
