import React, { useState } from 'react';
import { AdjustmentsVerticalIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid';

interface ScrollSnapSliderProps {
  onOptionChange: (option: number | null) => void;
  onSliderChange: (value: number) => void;
  onFontStyleChange: (style: string) => void;
  onFontSizeChange: (size: string) => void;
  onFontWeightChange: (weight: string) => void;
}

const ScrollSnapSlider: React.FC<ScrollSnapSliderProps> = ({
  onOptionChange,
  onSliderChange,
  onFontStyleChange,
  onFontSizeChange,
  onFontWeightChange,
}) => {

  const fontSizeValues = ['Default', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl']
  const fontWeightValues = ['Default', 'light', 'normal', 'medium', 'semibold', 'bold']

  const values = [14, 16, 20, 24, 32];
  const defaultValueIndex = values.indexOf(20);
  const [sliderValue, setSliderValue] = useState(defaultValueIndex);
  const [isRowSpacing, setIsRowSpacing] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(e.target.value, 10);
    setSliderValue(index);
    const selectedValue = values[index];
    onSliderChange(selectedValue);
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value, 10);
    onOptionChange(isNaN(value) ? null : value);
  };

  const handleFontStyleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onFontStyleChange(e.target.value);
  };

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onFontSizeChange((e.target.value));
  };

  const handleFontWeightChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onFontWeightChange(e.target.value);
  };

  const toggleSpacing = () => {
    setIsRowSpacing(!isRowSpacing);
  };

  const handleTooltip = (show: boolean) => {
    setShowTooltip(show);
  };

  return (
    <div>
      {/* Layout Configuration Section */}
      <label className="block text-gray-700 font-medium mb-5">
        Layout Configuration
      </label>

      {/* Row and Column Spacing Options */}
      <div className='flex justify-between gap-12'>
        {/* Per Row Select */}
        <div className="w-2/5">
          <label htmlFor="per-row-select" className="block mb-4 text-sm font-normal text-spring-leaves-800">
            Per Row
          </label>
          <select
            id="per-row-select"
            onChange={handleOptionChange}
            className="block w-full rounded-md border-0 px-3.5 py-2 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 focus:outline-none focus:ring-2 focus:ring-spring-leaves-500 sm:text-sm sm:leading-6"
          >
            <option value="">Auto</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>

        {/* Slider for Spacing */}
        <div className='w-3/5'>
          <style>
            {`
              input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 20px;
                height: 20px;
                background: gray;
                cursor: pointer;
                border-radius: 50%;
              }

              input[type='range']::-moz-range-thumb {
                width: 20px;
                height: 20px;
                background: gray;
                cursor: pointer;
                border-radius: 50%;
              }
            `}
          </style>
          <div className='flex items-center justify-center'>
            <label htmlFor="steps-range" className="mb-4 text-sm font-normal text-spring-leaves-800 w-3/5 flex items-center justify-between">
              <span className="text-center flex-1">{isRowSpacing ? 'Row Spacing' : 'Column Spacing'}</span>
              <div className="relative">
                {isRowSpacing
                  ? <>
                      <AdjustmentsVerticalIcon 
                        onMouseEnter={() => handleTooltip(true)} 
                        onMouseLeave={() => handleTooltip(false)}
                        onClick={toggleSpacing} 
                        className='w-5 h-5 text-spring-leaves-800 cursor-pointer hover:bg-spring-leaves-300 hover:text-spring-leaves-900 bg-spring-leaves-100 rounded-md transition duration-300 ease-in-out animate-pulse hover:animate-none' 
                      />
                      {showTooltip && <div className="absolute left-0 -ml-20 w-max bg-zinc-500 mb-1 text-white text-xs rounded py-1 px-2" style={{ bottom: '100%' }}>Toggle Row/Column Spacing</div>}
                    </>
                  : <>
                      <AdjustmentsHorizontalIcon 
                        onMouseEnter={() => handleTooltip(true)} 
                        onMouseLeave={() => handleTooltip(false)}
                        onClick={toggleSpacing} 
                        className='w-5 h-5 text-spring-leaves-800 cursor-pointer hover:bg-spring-leaves-300 hover:text-spring-leaves-900 bg-spring-leaves-100 rounded-md transition duration-300 ease-in-out animate-pulse hover:animate-none' 
                      />
                      {showTooltip && <div className="absolute left-0 -ml-20 w-max bg-zinc-500 mb-1 text-white text-xs rounded py-1 px-2" style={{ bottom: '100%' }}>Detailed formatting</div>}
                    </>
                }
              </div>
            </label>
          </div>
          
          <input
            id="steps-range"
            type="range"
            min="0"
            max={values.length - 1}
            value={sliderValue}
            step="1"
            onChange={handleSliderChange}
            className="w-full h-4 bg-zinc-200 rounded-lg appearance-none cursor-pointer focus:outline-none"
            style={{
              backgroundSize: `${(sliderValue / (values.length - 1)) * 100}% 100%`,
              backgroundImage: `linear-gradient(to right, spring-leaves-600 0%, spring-leaves-500 100%)`
            }}
          />

          <div className="flex justify-between overflow-x-auto snap-x mt-2">
            {values.map((value, index) => (
              <div key={index} className={`flex-shrink-0 snap-center w-10 h-10 flex items-center justify-center ${sliderValue === index ? 'border-spring-leaves-600 bg-spring-leaves-100' : 'border-gray-300'} rounded-full transition duration-300 ease-in-out transform`}>
                <span className="text-sm text-spring-leaves-800">{value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Font Configuration Options */}
      <div className="flex justify-between gap-12 mt-6">
        {/* Font Style Dropdown */}
        <div className="w-1/3">
          <label htmlFor="font-style-select" className="block mb-4 text-sm font-normal text-spring-leaves-800">
            Font Style
          </label>
          <select
            id="font-style-select"
            onChange={handleFontStyleChange}
            className="block w-full rounded-md border-0 px-3.5 py-2 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 focus:outline-none focus:ring-2 focus:ring-spring-leaves-500 sm:text-sm sm:leading-6"
          >
            <option value="normal">Default</option>
            <option value="italic">Italic</option>
            <option value="oblique">Oblique</option>
          </select>
        </div>

        {/* Font Size Dropdown */}
        {/* lg:x-small, xl:small, 2xl:normal, 3xl:large, 4xl:extra-large: 5xl:xxl  */}
        <div className="w-1/3">
          <label htmlFor="font-size-select" className="block mb-4 text-sm font-normal text-spring-leaves-800">
            Font Size
          </label>
          <select
            id="font-size-select"
            onChange={handleFontSizeChange}
            className="block w-full rounded-md border-0 px-3.5 py-2 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 focus:outline-none focus:ring-2 focus:ring-spring-leaves-500 sm:text-sm sm:leading-6"
          >
            {fontSizeValues.map((size) => (
              <option key={size} value={size}>{`${size}`}</option>
            ))}
          </select>
        </div>

        {/* Font Weight Dropdown */}
        {/* light, normal, medium, semibold, bold*/}
        <div className="w-1/3">
          <label htmlFor="font-weight-select" className="block mb-4 text-sm font-normal text-spring-leaves-800">
            Font Weight
          </label>
          <select
            id="font-weight-select"
            onChange={handleFontWeightChange}
            className="block w-full rounded-md border-0 px-3.5 py-2 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 focus:outline-none focus:ring-2 focus:ring-spring-leaves-500 sm:text-sm sm:leading-6"
          >
            {fontWeightValues.map((size) => (
              <option key={size} value={size}>{`${size}`}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default ScrollSnapSlider;
