import { DocumentTextIcon, UserIcon, ShieldCheckIcon, LockClosedIcon, QrCodeIcon, ExclamationCircleIcon, ClockIcon, GlobeAltIcon, MapIcon, LinkIcon, PencilIcon, XCircleIcon, MusicalNoteIcon} from '@heroicons/react/24/outline'

const terms = [
  {
    name: 'Acceptance of Terms',
    description: 'By accessing and using the Berni App ("App"), you acknowledge your understanding of, and agree to be bound by, these Terms of Use ("Terms"). If you disagree with any part of these Terms, you must refrain from using the App.',
    icon: DocumentTextIcon,
  },
  {
    name: 'Account Registration',
    description: 'To access specific functionalities within the App, registration might be necessary. During this process, you commit to providing information that is accurate, up-to-date, and comprehensive. It is your responsibility to keep this information updated as necessary.',
    icon: UserIcon,
  },
  {
    name: 'Privacy Practices',
    description: 'Your interactions with the App are also subject to our Privacy Policy. You can review our data practices and commitment to your privacy at [link to Privacy Policy].',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Usage Limitations',
    description: 'The following actions are strictly prohibited: Decompiling, reverse engineering, disassembling, or attempting to uncover the App\'s source code; using the App for any illicit purposes or in any manner that breaches these Terms.',
    icon: LockClosedIcon,
  },
  {
    name: 'Termination Rights',
    description: 'Berni holds the authority to, at its sole discretion, terminate or suspend your account and your access to the App. This can be due to reasons including, but not limited to, a breach of these Terms, actions that may harm other App users, third parties, or Berni itself.',
    icon: ExclamationCircleIcon,
  },
  {
    name: 'Alterations to the Terms',
    description: 'Berni reserves the right to modify these Terms at its discretion. Any updated versions will be made available in the App promptly. If you continue to use the App post these changes, it indicates your acceptance of the revised terms.',
    icon: ClockIcon,
  },
  {
    name: 'No Warranties',
    description: 'The App is offered to you on an "as-is" and "as-available" basis, without any warranties, either direct or implied. This includes warranties of merchantability, suitability for a specific purpose, or non-infringement.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Limitation of Liability',
    description: 'Berni shall not bear any responsibility for indirect, incidental, special, consequential, or exemplary damages. This includes damages for loss of profits, goodwill, data, or other intangible losses that arise from your use or inability to use the App.',
    icon: QrCodeIcon,
  },
  {
    name: 'Get in Touch',
    description: 'Should you have any queries or require clarification about these Terms, please reach out to us at [Your Contact Information].',
    icon: MapIcon,
  },
  {
        name: 'Intellectual Property',
        description: 'All content, logos, and other visual media created by us are our property and are protected by copyright laws. Unauthorized use of our intellectual property is strictly prohibited.',
        icon: MusicalNoteIcon,
    },
    {
        name: 'Termination',
        description: 'We reserve the right to terminate any accounts or bar access to our App and services at our sole discretion, especially in the event of user misconduct. This can include, but is not limited to, breaches of our Terms of Use.',
        icon: XCircleIcon,
    },
    {
        name: 'Governing Law',
        description: 'These Terms are governed by the laws of the country in which our company is headquartered, without regard to its conflict of laws provisions.',
        icon: GlobeAltIcon,
    },
    {
        name: 'Links to Other Websites',
        description: 'Our App may contain links to third-party websites or services that are not owned or controlled by us. We have no control over and assume no responsibility for the content, terms, or practices of any third-party websites or services.',
        icon: LinkIcon,
    },
    {
        name: 'User-Generated Content',
        description: 'Users retain all rights to the content they create and publish on our App. However, by posting content, users grant us a license to use, reproduce, and display such content.',
        icon: PencilIcon,
    },
    {
        name: 'DMCA Notice',
        description: 'We respect the intellectual property rights of others and expect users to do the same. In accordance with the Digital Millennium Copyright Act, we will respond to clear notices of alleged copyright infringement.',
        icon: DocumentTextIcon,
    },
    {
        name: 'Limitations on Use',
        description: 'By using our App, users agree not to engage in activities that may harm the platform, its users, or third parties. Specific prohibited activities will be outlined in detail within our agreement.',
        icon: XCircleIcon,
    },
]

export default function Terms() {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-spring-leaves-600">Berni AI - Terms of Use</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-spring-leaves-900 sm:text-4xl">
                        Read our terms and conditions
                    </p>
                    <p className="mt-6 text-sm leading-8 text-zinc-400">
                        Last Updated: Oct 25, 2023
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {terms.map((term) => (
                            <div key={term.name} className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-black">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-spring-leaves-500">
                                        <term.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    {term.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-black">{term.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}