// Summary.tsx

import React from 'react';

interface SummaryItem {
  title: string;
  value: string | number;
}

interface FeedbackCardProps {
  label: string;
  message: string;
  colorClass: string;
}

const FeedbackCard: React.FC<FeedbackCardProps> = ({ label, message, colorClass }) => {
  return (
    <li className={`p-4 rounded-lg flex items-center ${colorClass}`}>
      <span className="font-medium mr-2">{label}:</span>
      {message}
    </li>
  );
};

const summaryItems: SummaryItem[] = [
  {
    title: 'Great at',
    value: 'Times Tables (95%)',
  },
  {
    title: 'Keep Practicing',
    value: 'Word Problems (70%)',
  },
  {
    title: 'Classroom vs State',
    value: '+3.5%',
  },
  {
    title: 'Projected EOY Math Level',
    value: 'Above Average',
  },
];

const ChildSummary: React.FC = () => {
  const helpfulTip = 'Practice makes perfect! Keep working on those tricky word problems with David.';


  return (
    <div className="p-4 rounded-lg space-y-4">
      <h2 className="text-lg font-semibold text-spring-leaves-700 mt-2 mb-2 italic">David's Performance Summary</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {summaryItems.map((item, index) => (
          <div
            key={index}
            className={`p-3 mx-4 rounded-lg flex flex-col items-center text-center ${
              index % 2 === 0 ? 'bg-gradient-to-r from-spring-leaves-600 to-spring-leaves-700' : 'bg-gradient-to-r from-spring-leaves-700 to-spring-leaves-900'
            }`}
          >
            <h3 className="text-white font-semibold">{item.title}</h3>
            <p className="text-gray-300">{item.value}</p>
          </div>
        ))}
      </div>
       <div className="text-gray-900 p-4 rounded-lg space-y-4">
        <ul className="space-y-4">
          <FeedbackCard
            label="Star Student"
            message="David is doing great with multiplication and times tables!"
            colorClass="bg-green-100 text-green-800"
          />
          <FeedbackCard
            label="Needs Practice"
            message="Word problems are challenging. A little more practice will make a big difference!"
            colorClass="bg-yellow-100 text-yellow-800"
          />
          <FeedbackCard
            label="Alert"
            message="David's performance is below the state average in math!"
            colorClass="bg-red-100 text-red-800"
          />
          <FeedbackCard
            label="Berni Tip"
            message="Use the 'Generate' button to create custom practice sheets to help David improve in challenging areas."
            colorClass="bg-blue-100 text-blue-800"
          />
        </ul>
    </div>


      <div className="mt-4">
        <button className="w-full sm:w-auto bg-spring-leaves-700 hover:bg-spring-leaves-600 text-white font-bold py-2 px-4 rounded">
          Generate
        </button>
        <p className="mb-3 text-gray-500 dark:text-gray-400 font-light text-sm mt-1">Generate a worksheet based on classroom report.</p>
      </div>
    </div>
  );
};

export default ChildSummary;
