import React, { useState, useEffect } from 'react';
import WorksheetHeader from './common/WorksheetHeader';
import WorksheetTip from './common/WorksheetTip';
import WorksheetFooter from './common/WorksheetFooter';
import generateMathProblems from './formats/Math/generateMathProblems';
import MathFormatOne from './formats/Math/MathFormat-1';

interface TopicItem {
  topic: string; 
  format: string;
  quantity: number;
}

interface Problem {
  num1: number;
  num2: number;
  operator: string;
  format: string; // Added format here
}

interface WorksheetProps {
  details: {
    worksheetType: string; //subject
    problemCount: number;
    difficulty: string;
    topics?: TopicItem[]; //e.g [{topic: 'addition', format: 'A', quantity: 10}, {topic: 'subtraction', format: 'B', quantity: 10}]
    customInstructions: string; // will use for addition instructions for gpt 4 api
    tipContent?: string;
    studentName?: string;
    title?: string;
    perRow?: number;
    sliderValue?: number;
    hideTip?: boolean;
    fontStyle?: string;
    fontSize?: string;
    fontWeight?: string;
  };
}

const Worksheet: React.FC<WorksheetProps> = ({ details }) => {
  const {
    topics,
    tipContent,
    studentName,
    title,
    hideTip,
    fontStyle,
    fontSize,
    fontWeight,
  } = details;
  const [problems, setProblems] = useState<Problem[]>([]);

  useEffect(() => {
    if (topics) {
      //will also need to pass in difficulty and customInstructions for gpt-api to generate problems
      const generatedProblems = generateMathProblems(topics);
      setProblems(generatedProblems);
    }
  }, [topics]);

  return (
    <div
      className="main-worksheet relative border-2 bg-white p-8 flex flex-col"
      id="main-worksheet"
      style={{ height: '100%' }}
    >
      <WorksheetHeader title={title} name={studentName} />

      {/* Conditional Rendering for Tip */}
      {hideTip ? <div className="mt-2" /> : <WorksheetTip tipContent={tipContent} />}

      {/* Debug Section for Font Style, Size, and Weight
      <div className="font-debug-section my-4">
        <p>
          <strong>Font Style:</strong> {fontStyle || 'Default'}
        </p>
        <p>
          <strong>Font Size:</strong> {fontSize || 'Default'}
        </p>
        <p>
          <strong>Font Weight:</strong> {fontWeight || 'Default'}
        </p>
      </div> */}

      <MathFormatOne problems={problems} details={details} />

      <WorksheetFooter />
    </div>
  );
};

export default Worksheet;
