import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import kueLogo from '../assets/Berni-3.png';
import { Link } from 'react-router-dom';

const navigation = [
  { name: 'Pricing', href: '/pricing', current: false },
  { name: 'Contact', href: '/contact', current: false },
  { name: 'Blog', href: '/blog', current: false },
  { name: 'FAQ', href: '/faq', current: false },
];

const dropdownItems = [
  { name: 'For Educators', href: '/for-educators' },
  { name: 'For Parents', href: '/for-parents' },
];

function classNames(...classes: (string | undefined | null | boolean)[]) {
  return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex items-center">
                <Link to="/">
                  <img
                    className="h-16 mt-4 w-auto ml-2"
                    src={kueLogo}
                    alt="Berni AI Logo"
                  />
                </Link>
              </div>
              <div className="hidden sm:flex sm:space-x-4">
                                {/* Dropdown for Educators and Parents */}
                <div className="relative">
                  <button
                    onClick={toggleDropdown}
                    className="flex items-center text-black hover:bg-spring-leaves-200 rounded-md px-3 py-2 text-sm font-medium"
                  >
                    Features <ChevronDownIcon className="ml-1 mt-1 h-3 w-3" aria-hidden="true" />
                  </button>
                  {isDropdownOpen && (
                    <div className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                      <ul className="py-1 text-sm text-gray-700">
                        {dropdownItems.map((item) => (
                          <li key={item.name}>
                            <Link
                              to={item.href}
                              className="block px-4 py-2 hover:bg-gray-100"
                              onClick={() => setIsDropdownOpen(false)}
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {/* End of Dropdown */}
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-200 text-black' : 'text-black hover:bg-spring-leaves-200',
                      'rounded-md px-3 py-2 text-sm font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Link>
                ))}


              </div>
              <div className="flex items-center">
                <Link 
                  to="/news-letter" 
                  className="text-sm px-4 py-2 font-medium text-spring-leaves-900 border border-spring-leaves-400 rounded-md hover:bg-spring-leaves-200 hover:text-black">
                  Join Today!
                </Link>
                <div className="sm:hidden ml-3">
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-black hover:bg-spring-leaves-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-spring-leaves-300">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden bg-white">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-200 text-black' : 'text-black hover:bg-spring-leaves-400',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Link>
              ))}
              {/* Mobile dropdown items */}
              {dropdownItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-black hover:bg-spring-leaves-400 block rounded-md px-3 py-2 text-base font-medium"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
