import { UserCircleIcon, CircleStackIcon, ShieldExclamationIcon, GlobeAltIcon, CogIcon, UserGroupIcon, ChatBubbleBottomCenterIcon, CreditCardIcon } from '@heroicons/react/24/outline'

const policies = [
    {
        name: 'Personal Information Collection',
        description: 'We collect personal information like names, email addresses, and contact numbers when users register or sign up on our platform. This information is primarily used to provide a personalized experience.',
        icon: UserCircleIcon,
    },
    {
        name: 'Data Storage and Security',
        description: 'We value your trust in providing us your personal information, thus we are striving to use commercially acceptable means of protecting it. Data is securely stored and encrypted in our databases.',
        icon: CircleStackIcon,
    },
    {
        name: 'Cookies and Tracking',
        description: 'Our platform uses cookies to enhance user experience and track user activities to better understand their preferences and improve our services.',
        icon: CogIcon,
    },
    {
        name: 'Third-party Services',
        description: 'Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites.',
        icon: GlobeAltIcon,
    },
    {
        name: 'User Rights',
        description: 'Users have the right to access, rectify, or erase their personal information from our platform. Requests can be made directly through account settings or by contacting our support.',
        icon: UserGroupIcon,
    },
    {
        name: 'Communication Preferences',
        description: 'Users can opt-in or opt-out of our marketing communications. Preferences can be set through the account settings or using the unsubscribe link in our emails.',
        icon: ChatBubbleBottomCenterIcon,
    },
    {
        name: 'Payments and Financial Data',
        description: 'If users make a purchase on our platform, their financial data like credit card information is securely processed through third-party payment gateways. We do not store financial data.',
        icon: CreditCardIcon,
    },
    {
        name: 'Policy Updates',
        description: 'Our Privacy Policy may be updated periodically. Registered users will be notified of significant changes. It\'s advised to review this policy periodically for any changes.',
        icon: ShieldExclamationIcon,
    },
]

export default function Privacy() {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-spring-leaves-600">Berni AI - Privacy Policy</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-spring-leaves-900 sm:text-4xl">
                        Understand our commitment to your privacy
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {policies.map((policy) => (
                            <div key={policy.name} className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-black">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-spring-leaves-500">
                                        <policy.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    {policy.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-black">{policy.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
