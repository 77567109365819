import React from 'react';
import teacherHeadshot from '../../assets/TeacherHeadshots/teacher-1.png';
import teacherHeadshot2 from '../../assets/TeacherHeadshots/teacher-2.png';
import teacherHeadshot3 from '../../assets/TeacherHeadshots/teacher-3.png';

interface Testimony {
  image: string;
  name: string;
  role: string;
  quote: string;
}

const testimonies: Testimony[] = [
  {
    image: teacherHeadshot,
    name: 'Alice Brown',
    role: 'Elementary School Teacher',
    quote: "Berni AI's dynamic worksheets have transformed my teaching. The individual attention each student receives is phenomenal."
  },
  {
    image: teacherHeadshot2,
    name: 'Emma Johnson',
    role: 'Middle School Educator',
    quote: 'The real-time analytics have helped me understand my class performance on a deeper level. This tool is a must-have for any educator.'
  },
  {
    image: teacherHeadshot3,
    name: 'David Wilson ',
    role: 'Parent',
    quote: "It's amazing to see my child so engaged with learning. The custom homework creation feature ensures that he is always challenged."
  },
];

const TestimonySection: React.FC = () => {
  return (
    <div className="py-12">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-2xl font-semibold text-center text-spring-leaves-700 mb-6">What Our Community Says</h2>
        {testimonies.map((testimony, index) => (
          <div key={index} className="mb-1 last:mb-0">
            <div className="relative p-8">
              {/* <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-spring-leaves-200 via-spring-leaves-300 to-spring-leaves-400 shadow-lg"></div> */}
              <div className="relative flex flex-col lg:flex-row lg:items-center bg-gray-100 rounded-xl shadow-lg py-2">
                <img
                  src={testimony.image}
                  alt={testimony.name}
                  className="w-32 h-32 rounded-full mx-auto -mt-16 border-4 border-gray shadow-xl"
                />
                <div className="pt-16 lg:pt-0 lg:pl-8 text-center lg:text-left">
                  <blockquote>
                    <p className="text-lg font-medium font-style: italic text-gray-600">“{testimony.quote}”</p>
                  </blockquote>
                  <figcaption className="mt-4">
                    <p className="font-semibold text-spring-leaves-800">{testimony.name}</p>
                    <p className="text-sm text-gray-500">{testimony.role}</p>
                  </figcaption>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonySection;
