import React from 'react';
import BerniLogo from '../assets/Berni-dog.png';
import { Link } from 'react-router-dom';

export function SignIn() {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-8 w-auto"
          src={BerniLogo}
          alt="Berni AI"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-spring-leaves-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST">
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-spring-leaves-900">
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 py-1.5 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 placeholder:text-spring-leaves-400 focus:ring-2 focus:ring-inset focus:ring-spring-leaves-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-spring-leaves-900">
                Password
              </label>
              <div className="text-sm">
                <Link to="/forgot-password" className="font-semibold text-spring-leaves-700 hover:text-spring-leaves-800">
                  Forgot password?
                </Link>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 placeholder:text-spring-leaves-400 focus:ring-2 focus:ring-inset focus:ring-spring-leaves-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <Link
              to="/onboarding"
              className="flex w-full justify-center rounded-md bg-spring-leaves-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-spring-leaves-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-spring-leaves-800"
            >
              Sign in
            </Link>
          </div>
        </form>

        <p className="mt-10 mb-28 text-center text-sm text-spring-leaves-600">
          Not a member?{' '}
          <Link to="/join" className="font-semibold leading-6 text-spring-leaves-700 hover:text-spring-leaves-800">
            Create an account
          </Link>
        </p>
      </div>
    </div>
  );
}

export function Register() {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-8 w-auto"
          src={BerniLogo}
          alt="Berni AI"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-spring-leaves-900">
          Create your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST">
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-spring-leaves-900">
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 py-1.5 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 placeholder:text-spring-leaves-400 focus:ring-2 focus:ring-inset focus:ring-spring-leaves-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-spring-leaves-900">
              Password
            </label>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 placeholder:text-spring-leaves-400 focus:ring-2 focus:ring-inset focus:ring-spring-leaves-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-spring-leaves-900">
              Confirm Password
            </label>
            <div className="mt-2">
              <input
                id="confirm-password"
                name="confirm-password"
                type="password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 placeholder:text-spring-leaves-400 focus:ring-2 focus:ring-inset focus:ring-spring-leaves-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <Link
              to="/onboarding"
              className="flex w-full justify-center rounded-md bg-spring-leaves-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-spring-leaves-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-spring-leaves-800"
            >
              Register
            </Link>
          </div>
        </form>

        <p className="mt-10 mb-28 text-center text-sm text-spring-leaves-600">
          Already have an account?{' '}
          <Link to="/log-in" className="font-semibold leading-6 text-spring-leaves-700 hover:text-spring-leaves-800">
            Sign in
          </Link>
        </p>
      </div>
    </div>
  );
}
