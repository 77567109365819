import { CalendarDaysIcon, HandRaisedIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Newsletter() {
  const [subscribed, setSubscribed] = useState(false)
  const [email, setEmail] = useState('')
  const navigate = useNavigate() // Renamed for clarity

  const handleSubscribe = () => {
    // Perform subscription logic here
    setSubscribed(true)
    setTimeout(() => {
      navigate('/blog') // Updated to use the navigate function
    }, 2000)
  }

  const isEmailValid = (email: string) => {
    // Add your email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  return (
    <div className="mb-20 mt-16 relative isolate overflow-hidden py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-spring-leaves-700 sm:text-4xl text-left">Sorry, we're not ready yet...</h2>
            <p className="mt-2 text-lg leading-8 text-gray-700 text-left">
              Our team is working hard to bring the product to you as soon as possible. Leave your email to get alerted when you can start.
            </p>
            <div className="mt-6 flex max-w-md gap-x-4">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-spring-leaves-400 sm:text-sm sm:leading-6"
                placeholder="Get alerted via email"
                value={email}
                onChange={handleEmailChange}
              />
              <button
                type="submit"
                className={`flex-none rounded-md bg-spring-leaves-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-spring-leaves-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-800 ${!isEmailValid(email) ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleSubscribe}
                disabled={!isEmailValid(email)}
              >
                Subscribe
              </button>
            </div>
            {subscribed && (
              <p className="mt-12 text-spring-leaves-500 text-left font-semibold">
                Thanks for joining the waitlist! You will now be redirected to our blogs page.
              </p>
            )}
          </div>
          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-gray-300">
                <CalendarDaysIcon className="h-6 w-6 text-spring-leaves-800" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold text-black text-left">Bi-weekly updates</dt>
              <dd className="mt-2 leading-7 text-gray-600 text-left">
                Stay informed with our progress and updates.
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-gray-300">
                <HandRaisedIcon className="h-6 w-6 text-spring-leaves-800" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold text-black text-left">No spam</dt>
              <dd className="mt-2 leading-7 text-gray-600 text-left">
                We respect your privacy. Only important updates.
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
      </div>
    </div>
  )
}
