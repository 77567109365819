import React, { useEffect, useState } from 'react';

interface Problem {
  num1: number;
  num2: number;
  operator: string;
  format: string;
}

interface MathProblemsF1Props {
  problems: Problem[];
  details: {
    problemCount: number;
    perRow?: number;
    sliderValue?: number;
    fontStyle?: string;
    fontSize?: string; // Updated type to string to match class names like 'lg', 'xl', '2xl', etc.
    fontWeight?: string;
  };
}

const MathFormatOne: React.FC<MathProblemsF1Props> = ({ problems, details }) => {
  const { problemCount, perRow, sliderValue, fontStyle, fontSize, fontWeight } = details;
  console.log('fontStyle', fontStyle);
  console.log('fontSize', fontSize);
  console.log('fontWeight', fontWeight);

  let gapValue = 20; // Default gap value for problemCount <= 20
  let colsValue = 4; // Default number of columns

  if (problemCount > 20) {
    gapValue = 16;
  } else if (problemCount <= 16) {
    gapValue = 24;
  }

  if (perRow === 3) {
    colsValue = 3;
  } else if (perRow === 4) {
    colsValue = 4;
  } else if (perRow === 5) {
    colsValue = 5;
  }

  const [currentGapValue, setCurrentGapValue] = useState(sliderValue || gapValue);
  const [currentColsValue, setCurrentColsValue] = useState(perRow || colsValue);

  useEffect(() => {
    if (typeof sliderValue === 'number' && sliderValue >= 14 && sliderValue <= 33) {
      setCurrentGapValue(sliderValue);
    } else {
      setCurrentGapValue(gapValue);
    }
  }, [problemCount, sliderValue, gapValue]);

  useEffect(() => {
    if (perRow && perRow >= 2 && perRow <= 5) {
      setCurrentColsValue(perRow);
    } else {
      setCurrentColsValue(4);
    }
  }, [perRow]);

  const renderProblem = (problem: Problem, index: number) => {
    switch (problem.format) {
      case 'A':
        return renderFormatOne(problem, index);
      case 'B':
        return renderFormatTwo(problem, index);
      default:
        return renderFormatOne(problem, index);
    }
  };

function renderFormatOne(problem: Problem, index: number) {
  return (
    <div key={index} className="problem-div mb-6">
      <div className="text-xs font-semibold text-left ml-6">{index + 1})</div>
      <div className='inline-block border-b-2 border-black pl-4'>
        <p className={`text-${fontSize} font-${fontWeight} text-right`}>{problem.num1}</p>
        <div className="flex justify-center items-center">
          <span className={`text-${fontSize} font-${fontWeight} mr-2`}>{problem.operator}</span>
          <span className={`text-${fontSize} font-${fontWeight}`}>{problem.num2}</span>
        </div>
      </div>
    </div>
  );
}

function renderFormatTwo(problem: Problem, index: number) {
  return (
    <div key={index} className="problem-div mb-6 text-center">
      <div className="text-xs font-semibold text-left mb-6 ml-6">{index + 1})</div>
      <div className='inline-block'>
        <p className={`text-${fontSize} whitespace-nowrap font-${fontWeight}`}>{`${problem.num1} ${problem.operator} ${problem.num2} = `}</p>
      </div>
    </div>
  );
}


  return (
    <div className={`min-w-full grid grid-cols-${currentColsValue} gap-${currentGapValue} py-4 mb-2 mt-6`}>
      {problems.map((problem, index) => renderProblem(problem, index))}
    </div>
  );
};

export default MathFormatOne;
