import { useState } from 'react';
import TeacherOnboarding from '../components/TeacherOnboarding';
import ParentOnboarding from '../components/ParentOnboarding';

export default function MainOnboarding() {
  const [isTeacher, setIsTeacher] = useState(true);

  return (
    <div className={`isolate px-6 py-24 sm:py-32 lg:px-8 animate-fadeIn ${isTeacher ? 'bg-spring-leaves-0' : 'bg-spring-leaves-0'}`}>
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-spring-leaves-600 sm:text-4xl">Welcome to Berni!</h2>
        <p className="mt-2 text-lg leading-8 text-spring-leaves-700">
          Let's get started.
        </p>
      </div>
      <div className="mx-auto mt-10 max-w-md text-center">
        <div className="flex items-center justify-center gap-4 mb-4">
          <button 
            onClick={() => setIsTeacher(true)}
            className={`px-4 py-2 rounded-md ${isTeacher ? 'bg-spring-leaves-500 text-white' : 'bg-spring-leaves-200 text-spring-leaves-700'}`}
          >
            I'm a Teacher
          </button>
          <button 
            onClick={() => setIsTeacher(false)}
            className={`px-4 py-2 rounded-md ${!isTeacher ? 'bg-spring-leaves-500 text-white' : 'bg-spring-leaves-200 text-spring-leaves-700'}`}
          >
            I'm a Parent
          </button>
        </div>
        <div className="border-b border-spring-leaves-300 w-full mx-auto mt-10 mb-8"></div>
      </div>
      <div className="mt-0">
        {isTeacher ? <TeacherOnboarding /> : <ParentOnboarding />}
      </div>
    </div>
  );
}
