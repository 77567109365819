import React from 'react';
import Toggle from './components/Toggle'; // Adjust the path as per your project structure
import QuickOptionBadges from './components/QuickOptionBadges'; // Adjust the path
import WorksheetGeneratorForm from './components/WorksheetGeneratorForm'; // Adjust the path

// Define the type for the props
interface WorksheetInputWrapperProps {
  onDetailsChange: (newDetails: any) => void; // Replace 'any' with a more specific type if needed
}

const WorksheetInputWrapper: React.FC<WorksheetInputWrapperProps> = ({ onDetailsChange }) => {
  return (
    <div className="w-2/5 flex flex-col bg-white rounded-lg p-8 mb-10" 
      style={{ maxHeight: '1200px', overflow: 'auto' }}>
      <h2 className="text-2xl font-bold text-spring-leaves-600">Worksheet Customization</h2>
      <p className="text-gray-600 text-sm">
        Customize your worksheet to align with your teaching goals.
      </p>
      <Toggle />
      <QuickOptionBadges />
      <WorksheetGeneratorForm onDetailsChange={onDetailsChange} />
    </div>
  );
};

export default WorksheetInputWrapper;
