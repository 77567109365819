import React, { useState, useEffect } from 'react';
import { PlusIcon, XCircleIcon } from '@heroicons/react/24/outline';
import ScrollSnapSlider from './LayoutConfig';

interface TopicItem {
  topic: string;
  format: string;
  quantity: number;
}

interface UpdatedDetails {
  studentName?: string;
  title?: string;
  tipContent?: string;
  worksheetType?: string;
  problemCount?: number;
  topics?: TopicItem[];
  perRow?: number | null;
  sliderValue?: number;
  fontStyle?: string;
  fontSize?: string;
  fontWeight?: string;
  hideTip?: boolean;
}

interface WorksheetGeneratorFormProps {
  onDetailsChange: (newDetails: UpdatedDetails) => void;
}

const WorksheetGeneratorForm: React.FC<WorksheetGeneratorFormProps> = ({ onDetailsChange }) => {
  const [selectedSubject, setSelectedSubject] = useState<string>('');
  const [topicsList, setTopicsList] = useState<TopicItem[]>([{ topic: '', format: '', quantity: 0 }]);
  const [perRow, setPerRow] = useState<number | null>(null);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [isStudentNameDisabled, setIsStudentNameDisabled] = useState<boolean>(false);
  const [isWorksheetTitleDisabled, setIsWorksheetTitleDisabled] = useState<boolean>(false);
  const [isIncludeNoteDisabled, setIsIncludeNoteDisabled] = useState<boolean>(false);
  const [isRemoveChecked, setIsRemoveChecked] = useState(false);
  const [tempName, setStudentName] = useState<string>('');
  const [tempTitle, setWorksheetTitle] = useState<string>('');
  const [tempTip, setTip] = useState<string>("Remember to show your work step by step for every problem; this helps you track your thinking and may earn you partial credit even if the final answer isn't correct. Always double-check your work.");
  const [problemCountExceeded, setProblemCountExceeded] = useState<boolean>(false);

  const [fontStyle, setFontStyle] = useState<string>('');
  const [fontSize, setFontSize] = useState<string>('2xl');
  const [fontWeight, setFontWeight] = useState<string>('normal');

  useEffect(() => {
    const totalProblems = topicsList.reduce((total, item) => total + item.quantity, 0);
    setProblemCountExceeded(totalProblems > 80);

    const updatedDetails: UpdatedDetails = {
      studentName: isStudentNameDisabled ? '' : tempName,
      title: isWorksheetTitleDisabled ? '(Placeholder Title)' : tempTitle,
      tipContent: isIncludeNoteDisabled ? 'AI will generate a tip based on classroom data and worksheet problems you select!' : tempTip,
      worksheetType: selectedSubject,
      problemCount: totalProblems,
      topics: topicsList,
      perRow,
      sliderValue,
      hideTip: isRemoveChecked,
      fontStyle,
      fontSize,
      fontWeight,
    };

    onDetailsChange(updatedDetails);
  }, [tempName, tempTitle, tempTip, selectedSubject, topicsList, perRow, sliderValue, isRemoveChecked, fontStyle, fontSize, fontWeight, isStudentNameDisabled, isWorksheetTitleDisabled, isIncludeNoteDisabled]);

  const handleSubjectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSubject(e.target.value);
    setTopicsList([{ topic: '', format: '', quantity: 0 }]);
  };

  const handleStudentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const words = e.target.value.split(' ').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    setStudentName(words.join(' '));
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const smallWords = ['a', 'an', 'the', 'and', 'but', 'or', 'for', 'nor', 'on', 'in', 'at', 'to', 'from', 'by', 'with', 'if', 'of'];
    const words = e.target.value.split(' ').map((word, index, array) =>
      smallWords.includes(word.toLowerCase()) && index !== 0 && index !== array.length - 1
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    setWorksheetTitle(words.join(' '));
  };

  const handleTipChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTip(e.target.value);
  };

  const updateTopicItem = (index: number, field: keyof TopicItem, value: string | number) => {
    const newList = topicsList.map((item, idx) => idx === index ? { ...item, [field]: value } : item);
    setTopicsList(newList);
  };

  const handleAddTopic = () => {
    setTopicsList([...topicsList, { topic: '', format: '', quantity: 0 }]);
  };

  const handleRemoveTopic = (index: number) => {
    const newList = topicsList.filter((_, idx) => idx !== index);
    setTopicsList(newList);
  };


const handleOptionChange = (option: number | null) => {
  setPerRow(option);
};



  const handleSliderValueChange = (value: number) => {
    setSliderValue(value);
  };

  return (
    <form className="p-4 mb-10">
      <div className="space-y-6 max-w-4xl mx-auto">

        {/* Worksheet Title Entry */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center">
          <div className="md:col-span-3">
            <label htmlFor="worksheet-title" className="block text-gray-700 font-medium mb-2">
              Worksheet Title
            </label>
            <input
              type="text"
              id="worksheet-title"
              name="worksheet-title"
              maxLength={50}
              onChange={handleTitleChange}
              className={`block w-full rounded-md border-0 px-3.5 py-2 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 placeholder:text-spring-leaves-400 focus:ring-2 focus:ring-inset focus:ring-spring-leaves-600 sm:text-sm sm:leading-6 ${isWorksheetTitleDisabled ? 'bg-gray-300' : ''}`}
              placeholder="Enter worksheet title"
              disabled={isWorksheetTitleDisabled}
            />
          </div>
          <div className="flex items-center mt-7">
            <input
              id="worksheet-title-checkbox"
              name="worksheet-title-checkbox"
              type="checkbox"
              className="h-8 w-8 text-spring-leaves-600 focus:ring-spring-leaves-500 border-gray-300 rounded"
              checked={isWorksheetTitleDisabled}
              onChange={() => setIsWorksheetTitleDisabled(!isWorksheetTitleDisabled)}
            />
            <label htmlFor="worksheet-title-checkbox" className="ml-3 text-xs font-medium text-gray-700">
              Let AI Generate
            </label>
          </div>
        </div>

        {/* Student Name Entry */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center">
          <div className="md:col-span-3">
            <label htmlFor="student-name" className="block text-gray-700 font-medium mb-2">
              Student Name
            </label>
            <input
              type="text"
              id="student-name"
              name="student-name"
              maxLength={30}
              onChange={handleStudentNameChange}
              className={`block w-full rounded-md border-0 px-3.5 py-2 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 placeholder:text-spring-leaves-400 focus:ring-2 focus:ring-inset focus:ring-spring-leaves-600 sm:text-sm sm:leading-6 ${isStudentNameDisabled ? 'bg-gray-300' : ''}`}
              placeholder="Enter student's name"
              disabled={isStudentNameDisabled}
            />
          </div>
          <div className="flex items-center mt-7">
            <input
              id="student-name-checkbox"
              name="student-name-checkbox"
              type="checkbox"
              className="h-8 w-8 text-spring-leaves-600 focus:ring-spring-leaves-500 border-gray-300 rounded"
              checked={isStudentNameDisabled}
              onChange={() => setIsStudentNameDisabled(!isStudentNameDisabled)}
            />
            <label htmlFor="student-name-checkbox" className="ml-3 text-xs font-medium text-gray-700">
              Leave Blank
            </label>
          </div>
        </div>

{/* Berni Tip and Note */}
<div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center mt-6">
  {/* Text Area for Note */}
  <div className="md:col-span-3">
    <label htmlFor="include-note" className="block text-gray-700 font-medium mb-2">
      Include Note
    </label>
    <textarea
      id="include-note"
      name="include-note"
      onChange={handleTipChange}
      maxLength={200}
      className={`block placeholder:text-spring-leaves-400 w-full rounded-md border-0 px-3.5 py-2 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 focus:outline-none focus:ring-2 focus:ring-spring-leaves-500 sm:text-sm sm:leading-6 resize-none ${isIncludeNoteDisabled || isRemoveChecked? 'bg-gray-300' : ''}`}
      rows={4}
      placeholder="Leave a note or tips for the student here"
      disabled={isIncludeNoteDisabled || isRemoveChecked}
    />
  </div>

  {/* Container for Checkboxes */}
  <div className="md:col-span-1 flex md:flex-col justify-center">
    {/* Checkbox for Berni Tip */}
    <div className="flex items-center">
      <input
        id="include-berni-tip"
        name="include-berni-tip"
        type="checkbox"
        className="h-8 w-8 text-spring-leaves-600 focus:ring-spring-leaves-500 border-gray-300 rounded"
        checked={isIncludeNoteDisabled}
        onChange={() => setIsIncludeNoteDisabled(!isIncludeNoteDisabled)}
      />
      <label htmlFor="include-berni-tip" className="ml-3 text-xs font-medium text-gray-700">
        Berni Tip
      </label>
    </div>
    {/* Second Checkbox for Berni Tip (if needed) */}
    <div className="flex items-center mt-6 md:mt-4">
      <input
        id="include-second-berni-tip"
        name="include-second-berni-tip"
        type="checkbox"
        className="h-8 w-8 text-spring-leaves-600 focus:ring-spring-leaves-500 border-gray-300 rounded"
        checked={isRemoveChecked}
        onChange={() => setIsRemoveChecked(!isRemoveChecked)}
      />
      <label htmlFor="include-second-berni-tip" className="ml-3 text-xs font-medium text-gray-700">
        Hide Section
      </label>
    </div>
  </div>
</div>


        {/* Worksheet Type Selection */}
        <div>
          <label htmlFor="worksheet-type" className="block text-gray-700 font-medium mb-2">
            Subject
          </label>
          <select
            id="worksheet-type"
            name="worksheet-type"
            className="block w-full rounded-md border-0 px-3.5 py-2 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 placeholder:text-spring-leaves-420 focus:ring-2 focus:ring-inset focus:ring-spring-leaves-600 sm:text-sm sm:leading-6"
            value={selectedSubject}
            onChange={handleSubjectChange}
          >
            <option value="">Select a Subject</option>
            <option value="math">Math</option>
            <option value="science">Science</option>
            <option value="language">Language</option>
          </select>
        </div>

{/* Dynamic Topic Selectors */}
{selectedSubject === 'math' && topicsList.map((item, index) => (
  <div key={index} className="grid grid-cols-1 md:grid-cols-12 gap-6">
    {/* Math Topic Selector */}
    <div className="md:col-span-4">
      <select
        value={item.topic}
        onChange={(e) => updateTopicItem(index, 'topic', e.target.value)}
        className="block w-full rounded-md border-0 px-3.5 py-2 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 sm:text-sm sm:leading-6"
      >
        <option value="">Topic</option>
        <option value="addition">Addition</option>
        <option value="subtraction">Subtraction</option>
        <option value="multiplication">Multiplication</option>
        <option value="division">Division</option>
        {/* Add more math topics as needed */}
      </select>
    </div>

    {/* Format Selector */}
    <div className="md:col-span-4">
      <select
        value={item.format}
        onChange={(e) => updateTopicItem(index, 'format', e.target.value)}
        className="block w-full rounded-md border-0 px-3.5 py-2 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 sm:text-sm sm:leading-6"
      >
        <option value="">Format</option>
        <option value="A">Standard</option>
        <option value="B">Line</option>
        {/* Add more formats as needed */}
      </select>
    </div>

    {/* Quantity Input */}
    <div className="md:col-span-3">
      <input
        type="number"
        value={item.quantity}
        onChange={(e) => updateTopicItem(index, 'quantity', parseInt(e.target.value))}
        min="0"
        className="block w-full rounded-md border-0 px-3.5 py-2 text-spring-leaves-900 shadow-sm ring-1 ring-inset ring-spring-leaves-300 sm:text-sm sm:leading-6"
      />
    </div>

    {/* Remove Button */}
    <div className="md:col-span-1 flex items-center">
      {index > 0 && (
        <button
          type="button"
          onClick={() => handleRemoveTopic(index)}
          className="mt-2 text-red-500 hover:text-red-700"
        >
          <XCircleIcon className="h-6 w-6" />
        </button>
      )}
    </div>
  </div>
))}

      {/* Add New Topic Button */}
{selectedSubject === 'math' && (
  <div className="text-left mt-2"> 
    <button
      type="button"
      onClick={handleAddTopic}
      className="border-2 border-spring-leaves-300 text-spring-leaves-700 py-1 px-3 rounded-md inline-flex items-center hover:bg-spring-leaves-300 hover:text-white hover:border-transparent transition duration-300 ease-in-out" // Added hover effects
    >
      <PlusIcon className="h-6 w-6" />
    </button>
  </div>
)}
{problemCountExceeded && (
          <div className="text-red-500 text-xs mt-2">
            The total number of problems cannot exceed 80. Please adjust the quantities.
          </div>
        )}

{/* numbers per row and scroll slider */}
      <ScrollSnapSlider
        onOptionChange={handleOptionChange}
        onSliderChange={handleSliderValueChange}
        onFontStyleChange={setFontStyle} // Add this prop
        onFontSizeChange={setFontSize}   // Add this prop
        onFontWeightChange={setFontWeight} // Add this prop
      />

        {/* Save and Generate Button */}
        <div className="mt-10 flex justify-end space-x-4">
          <button
            type="submit"
            disabled={problemCountExceeded}
            className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-spring-leaves-600 ${!problemCountExceeded ? 'hover:bg-spring-leaves-700' : 'opacity-50 cursor-not-allowed'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spring-leaves-500`}
          >
            Generate
          </button>
        </div>
      </div>
    </form>
  );
}

export default WorksheetGeneratorForm;