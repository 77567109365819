import React, { useState } from 'react';
import WorksheetInputWrapper from '../components/Worksheet/WorksheetGenerator/WorksheetInputWrapper';
import Worksheet from '../components/Worksheet/Worksheet';

interface WorksheetDetails {
  worksheetType: string;
  problemCount: number;
  difficulty: string;
  topic: string;
  customInstructions: string;
}

const WorksheetGen: React.FC = () => {
  const [worksheetDetails, setWorksheetDetails] = useState<WorksheetDetails>({
    worksheetType: '',
    problemCount: 20,
    difficulty: 'medium',
    topic: '',
    customInstructions: ''
  });

  const handleDetailsChange = (newDetails: Partial<WorksheetDetails>) => {
    setWorksheetDetails(prevDetails => ({ ...prevDetails, ...newDetails }));
  };

  const customShadowStyle = {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1)',
  };

  return (
    <div className="my-4 mx-auto min-h-screen flex justify-center p-6 gap-x-4">
      <WorksheetInputWrapper onDetailsChange={handleDetailsChange} />

      {/* Worksheet preview component placeholder */}
      <div className='w-3/5 max-w-4xl' style={{ maxHeight: '1200px', ...customShadowStyle }}>
        <Worksheet details={worksheetDetails} />
      </div>
    </div>
  );
};

export default WorksheetGen;
