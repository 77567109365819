import React from 'react';
import { LightBulbIcon, PencilIcon, ChartBarIcon } from '@heroicons/react/20/solid';
import wsGenImg from '../../../assets/WS-gen.png';

const Article1: React.FC = () => {
  return (
    <div className="text-spring-leaves-900 py-8">
      <div className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <h1 className="text-3xl font-bold tracking-tight sm:text-4xl">Berni AI Launches AI Worksheet Generation Feature</h1>
                <p className="mt-6 text-xl leading-8">
                  Berni AI is excited to announce the launch of its groundbreaking AI-driven worksheet generation feature, designed to revolutionize the educational experience for both teachers and students.
                </p>
              </div>
            </div>
          </div>

          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className="w-[58rem] max-w-none rounded-xl bg-spring-leaves-900 shadow-xl ring-1 ring-spring-leaves-400/10 sm:w-[57rem]"
              src={wsGenImg}
              alt="AI Worksheet Feature"
            />
          </div>

          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="max-w-xl text-base leading-7 lg:max-w-lg">
                <p>
                  This innovative feature utilizes advanced AI algorithms to create dynamic, personalized worksheets that cater to the individual learning styles and levels of students. Teachers can now effortlessly generate custom worksheets, saving valuable time and resources while providing a more engaging learning experience.
                </p>
                <ul role="list" className="mt-8 space-y-8">
                  <li className="flex gap-x-3">
                    <PencilIcon className="mt-1 h-5 w-5 flex-none text-spring-leaves-600" aria-hidden="true" />
                    <span>
                      <strong className="font-semibold">Customization and Engagement:</strong> Create worksheets tailored to individual learning needs, enhancing engagement and comprehension.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <LightBulbIcon className="mt-1 h-5 w-5 flex-none text-spring-leaves-600" aria-hidden="true" />
                    <span>
                      <strong className="font-semibold">Innovative AI Technology:</strong> Harnessing the power of AI to generate unique and effective learning materials.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <ChartBarIcon className="mt-1 h-5 w-5 flex-none text-spring-leaves-600" aria-hidden="true" />
                    <span>
                      <strong className="font-semibold">Data-Driven Insights:</strong> Leverage AI analytics to track student progress and adapt materials for optimal learning outcomes.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Article1;
