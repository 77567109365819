import React from 'react';

interface Problem {
  num1: number;
  num2: number;
}

interface ProblemsSet {
  operator: 'multiplication' | 'addition' | 'subtraction';
  problemList: Problem[];
}

interface MathProblemsF1Props {
  problemsSet: ProblemsSet;
  format?: number;
}

const MathProblemsF1: React.FC<MathProblemsF1Props> = ({ problemsSet, format = 1 }) => {
  // Operator symbol based on the operation type
  const operatorSymbol = {
    'multiplication': '×',
    'addition': '+',
    'subtraction': '-',
  }[problemsSet.operator];

  // Grid columns and margins
  const gridStyles = getGridStyles(problemsSet.problemList.length);

  // Render problem based on the format
  const renderProblem = (problem: Problem, index: number) => {
    switch (format) {
      case 1:
        return renderFormatOne(problem, index, operatorSymbol, gridStyles.marginRight);
      case 2:
        return renderFormatTwo(problem, index, operatorSymbol);
      // Additional formats can be added here as case 3, case 4, etc.
      default:
        return renderFormatOne(problem, index, operatorSymbol, gridStyles.marginRight); // Fallback to format one
    }
  };

  return (
    <div className={`min-w-full grid ${gridStyles.gridColumns} gap-12 py-4 mb-6 mt-auto`}>
      {problemsSet.problemList.map((problem, index) => renderProblem(problem, index))}
    </div>
  );
};

// Helper function to determine grid columns and margin styles
function getGridStyles(problemCount: number) {
  let gridColumns = 'grid-cols-4';
  let marginRight = 'mr-10';

  if (problemCount > 20) {
    gridColumns = 'grid-cols-5';
  } else if (problemCount <= 12) {
    gridColumns = 'grid-cols-3';
    marginRight = 'mr-14';
  }

  return { gridColumns, marginRight };
}

// Format One: Standard worksheet format with numbers aligned vertically
function renderFormatOne(problem: Problem, index: number, operatorSymbol: string, marginRight: string) {
  return (
    <div key={index} className={`problem-div mb-6 text-right ${marginRight}`}>
      <div className="text-xs font-semibold text-left ml-4">{index + 1}.</div>
      <p className="text-2xl">{problem.num1}</p>
      <div className="flex justify-end items-center">
        <span className="text-2xl mr-2">{operatorSymbol}</span>
        <span className="text-2xl">{problem.num2}</span>
      </div>
      <div className='w-3/5 ml-auto mt-1 border-t border-black' style={{ borderWidth: '1px' }}></div>
    </div>
  );
}

// Format Two: Inline format with equals sign
function renderFormatTwo(problem: Problem, index: number, operatorSymbol: string) {
  return (
    <div key={index} className="problem-div mb-6 text-center">
      <div className="text-xs font-semibold text-left ml-4 mb-4">{index + 1}.</div>
      <p className="text-2xl">{`${problem.num1} ${operatorSymbol} ${problem.num2} = `}</p>
    </div>
  );
}

export default MathProblemsF1;
