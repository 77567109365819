import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowTrendingUpIcon, ArrowTrendingDownIcon } from '@heroicons/react/24/outline';
import { UserGroupIcon } from '@heroicons/react/24/outline';


const shadowStyle: React.CSSProperties = {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.10), 0px 8px 16px rgba(0, 0, 0, 0.08)'
};

interface Student {
  name: string;
  id: string;
  performanceScore: number;
  parentContactInfo: string;
  analytics: string;
  trajectory: number; 
}

const students: Student[] = [
  { 
    name: 'Aiden Martinez', 
    id: 'A1234', 
    performanceScore: 88, 
    parentContactInfo: 'mailto:aidensparent@example.com',
    analytics: '/analytics/A1234',
    trajectory: 2
  },
  { 
    name: 'Sophia Johnson', 
    id: 'A1235', 
    performanceScore: 82, 
    parentContactInfo: 'mailto:sophiaj@example.com',
    analytics: '/analytics/A1235',
    trajectory: 1
  },
  { 
    name: 'Oliver Smith', 
    id: 'A1236', 
    performanceScore: 78, 
    parentContactInfo: 'mailto:oliversmith@example.com',
    analytics: '/analytics/A1236',
    trajectory: -3
  },
  { 
    name: 'Mia Williams', 
    id: 'A1237', 
    performanceScore: 91, 
    parentContactInfo: 'mailto:miawilliams@example.com',
    analytics: '/analytics/A1237',
    trajectory: 4
  },
  { 
    name: 'Ethan Brown', 
    id: 'A1238', 
    performanceScore: 85, 
    parentContactInfo: 'mailto:ethanbrown@example.com',
    analytics: '/analytics/A1238',
    trajectory: -2
  },
  { 
    name: 'Emma Davis', 
    id: 'A1239', 
    performanceScore: 80, 
    parentContactInfo: 'mailto:emmadavis@example.com',
    analytics: '/analytics/A1239',
    trajectory: 0
  },
  { 
    name: 'Liam Wilson', 
    id: 'A1240', 
    performanceScore: 92, 
    parentContactInfo: 'mailto:liamwilson@example.com',
    analytics: '/analytics/A1240',
    trajectory: 5
  },
  { 
    name: 'Isabella Garcia', 
    id: 'A1241', 
    performanceScore: 77, 
    parentContactInfo: 'mailto:isabellagarcia@example.com',
    analytics: '/analytics/A1241',
    trajectory: -6
  },
  { 
    name: 'Noah Rodriguez', 
    id: 'A1242', 
    performanceScore: 89, 
    parentContactInfo: 'mailto:noahrodriguez@example.com',
    analytics: '/analytics/A1242',
    trajectory: 3
  },
  { 
    name: 'Ava Taylor', 
    id: 'A1243', 
    performanceScore: 75, 
    parentContactInfo: 'mailto:avataylor@example.com',
    analytics: '/analytics/A1243',
    trajectory: -4
  },
  { 
    name: 'Lucas Miller', 
    id: 'A1244', 
    performanceScore: 87, 
    parentContactInfo: 'mailto:lucasmiller@example.com',
    analytics: '/analytics/A1244',
    trajectory: 2
  },
  { 
    name: 'Charlotte Lee', 
    id: 'A1245', 
    performanceScore: 94, 
    parentContactInfo: 'mailto:charlottelee@example.com',
    analytics: '/analytics/A1245',
    trajectory: 6
  },
];


const Roster: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredStudents = students.filter(
    (student) =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.performanceScore.toString().includes(searchTerm) ||
      student.parentContactInfo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg max-w-screen-2xl w-full mx-auto " style={shadowStyle}>
        <div className="pb-4 bg-white flex flex-col md:flex-row justify-between items-start md:items-center">
        <div className="flex items-center mb-2 ml-3">
            <UserGroupIcon className="h-6 w-6 text-spring-leaves-600 mr-2" />
            <h1 className="font-semibold text-lg text-spring-leaves-600">Classroom Roster</h1>
        </div>
            <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg className="ml-1 w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColnpor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input 
                    type="text" 
                    id="table-search" 
                    className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-spring-leaves-300 focus:border-spring-leaves-300 mr-7 ml-2" 
                    placeholder="Search for students" 
                    value={searchTerm} 
                    onChange={e => setSearchTerm(e.target.value)} 
                />
            </div>
        </div>
        <div className="max-h-96 overflow-y-auto">
            <table className="w-full text-sm text-left">
                <thead>
                    <tr className="text-bold text-white">
                        <th className="text-spring-leaves-600 p-4">Name</th>
                        <th className="text-spring-leaves-600 p-4">ID</th>
                        <th className="text-spring-leaves-600 p-4">Performance Score</th>
                        <th className="text-spring-leaves-600 p-4">2-Week Shift</th>
                        <th className="text-spring-leaves-600 p-4">Analytics</th>
                        <th className="text-spring-leaves-600 p-4">Guardian Contact</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredStudents.map((student, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="p-4">{student.name}</td>
                            <td className="p-4">{student.id}</td>
                            <td className="p-4">{student.performanceScore}</td>
                            <td className="p-4 flex items-center">
                                {student.trajectory > 0 ? (
                                    <>
                                        <ArrowTrendingUpIcon className="h-5 w-5 text-green-500 mr-2" />
                                        {student.trajectory}%
                                    </>
                                ) : (
                                    <>
                                        <ArrowTrendingDownIcon className="h-5 w-5 text-red-500 mr-2" />
                                        {Math.abs(student.trajectory)}%
                                    </>
                                )}
                            </td>
                            <td className="p-4">
                                <Link to={student.analytics} className="text-spring-leaves-400 hover:underline">View</Link>
                            </td>
                            <td className="p-4">
                                <Link to={student.parentContactInfo.replace('mailto:', '/')} className="text-spring-leaves-400 hover:underline">Contact</Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
);
};

export default Roster;
