// MathWs.tsx
import React, { useState, useEffect } from 'react';
import WorksheetHeader from '../Worksheet/common/WorksheetHeader';
import WorksheetTip from '../Worksheet/common/WorksheetTip';
import MathProblemsF1 from './MathProblems-F1';
import ShapeCountingWS from './ShapeCountingWS';
import WorksheetFooter from '../Worksheet/common/WorksheetFooter';


const MathWs: React.FC = () => {
  
  const worksheetTitle = "Multi-Digit Math Practice"; 
  const userName = "Jordan Bell" // setting userName to '' will render a line for students to write in their names
  const berniTip = "Adding is like putting together your toy blocks! If you have 3 red blocks and 2 blue blocks, and you put them all together, you will have 5 blocks in total.";
  
  // Define the Problem and ProblemsSet interfaces here
  interface Problem {
    num1: number;
    num2: number;
  }
  
  type Operation = 'multiplication' | 'addition' | 'subtraction';
  
  interface ProblemsSet {
    operator: Operation;
    problemList: Problem[];
  }
  
  // State to hold the problems set
  const [problemsSet, setProblemsSet] = useState<ProblemsSet>({operator: 'multiplication', problemList: []});

  // Function to generate a set of problems
  function generateProblems(operation: Operation, count: number): ProblemsSet {
    const problemList: Problem[] = [];

    for (let i = 0; i < count; i++) {
      const num1 = Math.floor(Math.random() * 89) + 10; // random number between 10 and 98
      const num2 = Math.floor(Math.random() * 8) + 2; // random number between 2 and 9
      problemList.push({ num1, num2 });
    }

    return { operator: operation, problemList };
  }

  // Function to handle the print action
  const handlePrint = () => {
    window.print();
  };

  // Generate problems on component mount
  useEffect(() => {
    const newProblemsSet = generateProblems('subtraction', 20);
    setProblemsSet(newProblemsSet);
  }, []);

  return (
    <>
      <div className="main-worksheet relative bg-white p-8 flex flex-col " id="main-worksheet">
        {/* <WorksheetHeader worksheetTitle={worksheetTitle} name={userName}/> */}
        <WorksheetTip tipContent={berniTip}/>
        <MathProblemsF1 problemsSet={problemsSet} format={1}/>
        {/* <ShapeCountingWS /> */}
        <WorksheetFooter/>
      </div>

      {/* <button onClick={handlePrint} className="print:hidden mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Print Worksheet
      </button> */}
    </>
  );
};

export default MathWs;
