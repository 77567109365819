import React from 'react';
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid';

const About: React.FC = () => {
  return (
    <div className="text-spring-leaves-900 py-8">

      {/* Hero Section */}
      <div className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <p className="text-base font-semibold leading-7 text-spring-leaves-600">Exciting News!</p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">Announcing Our Next Funding Round!</h1>
                <p className="mt-6 text-xl leading-8">
                  We're thrilled to share that Berni AI is entering its next phase of growth. This new round of funding will empower us to further revolutionize the educational landscape. Join us in shaping the future of education.
                </p>
              </div>
            </div>
          </div>
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className="w-[48rem] max-w-none rounded-xl bg-spring-leaves-900 shadow-xl ring-1 ring-spring-leaves-400/10 sm:w-[57rem]"
              src="https://images.unsplash.com/photo-1541746972996-4e0b0f43e02a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80"
              alt="Berni AI Office"
            />
          </div>
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="max-w-xl text-base leading-7 lg:max-w-lg">
                <p>
                  Berni AI is a transformative platform that harnesses the power of artificial intelligence to redefine the educational experience. With our new round of funding, we're poised to expand our reach, enhance our platform, and bring Berni AI to educators and learners worldwide. This investment is not just in our technology, but in our vision of revolutionizing the educational landscape.
                </p>
                <ul role="list" className="mt-8 space-y-8">
                  <li className="flex gap-x-3">
                    <CloudArrowUpIcon className="mt-1 h-5 w-5 flex-none text-spring-leaves-600" aria-hidden="true" />
                    <span>
                      <strong className="font-semibold">AI-Driven Personalization:</strong> Leveraging LLMs for content creation ensures state-of-the-art worksheets tailored to individual needs.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-spring-leaves-600" aria-hidden="true" />
                    <span>
                      <strong className="font-semibold">Data Security and Privacy:</strong> We prioritize user data protection with basic encryption and GDPR compliance.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <ServerIcon className="mt-1 h-5 w-5 flex-none text-spring-leaves-600" aria-hidden="true" />
                    <span>
                      <strong className="font-semibold">Adaptive Learning Mechanism:</strong> Our platform captures student responses and adjusts difficulty in real-time, ensuring continuous and appropriate challenges.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default About;
