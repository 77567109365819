import { PlusCircleIcon, ChatBubbleOvalLeftEllipsisIcon, ArrowPathIcon, AcademicCapIcon, LightBulbIcon, ChartBarIcon} from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';
import teacherIMG from '../assets/teacher1.jpg'

const features = [
    {
      name: 'AI-crafted Printable Worksheets',
      description: 'Tailored worksheets crafted by AI for each student’s unique learning journey.',
      icon: PlusCircleIcon,
    },
    {
      name: 'Adaptive AI-crafted Worksheets',
      description: 'Worksheets that adjust in difficulty based on student performance, ensuring continuous challenges.',
      icon: ArrowPathIcon,
    },
    {
      name: 'Real-time Progress Dashboard',
      description: 'Instant insights into student performance and areas of improvement.',
      icon: ChatBubbleOvalLeftEllipsisIcon,
    },
    {
      name: 'Performance Comparison Analytics',
      description: "Compare your classroom's performance with the state average for your grade. Get insights into where you stand",
      icon: ChartBarIcon, 
    },
    {
      name: 'Interactive Quizzes',
      description: 'Test student understanding and reinforce learning with interactive quizzes.',
      icon: AcademicCapIcon,
    },
    {
      name: 'Parental Insights Dashboard',
      description: 'Deep insights into student performance, allowing for better communication with parents.',
      icon: ChatBubbleOvalLeftEllipsisIcon,
    },
    {
      name: 'Goal Setting & Tracking',
      description: 'Set, monitor, and achieve learning goals for each student.',
      icon: ArrowPathIcon,
    },
    {
      name: 'Personalized Learning Paths',
      description: 'Unique learning journeys tailored to each student’s needs and performance.',
      icon: PlusCircleIcon,
    },
    {
      name: 'Advanced Analytics',
      description: 'Deep dive into student performance metrics and gain insights for improved teaching.',
      icon: ChatBubbleOvalLeftEllipsisIcon,
    },
    
    {
      name: 'Customization Options for Worksheets',
      description: 'Tailor worksheets to better fit your teaching style and student needs.',
      icon: AcademicCapIcon,
    },
    {
      name: 'Progress Update Notification System',
      description: 'Stay informed about student progress with timely notifications.',
      icon: LightBulbIcon,
    }
  ];


export default function ForEducators() {
  return (
    <div className="overflow-hidden py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-spring-leaves-600">Key Features for Educators</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-spring-leaves-900 sm:text-4xl">For Educators</p>
              <p className="mt-6 text-lg leading-8 text-spring-leaves-600">
                Berni AI offers a transformative educational experience, harnessing AI to provide tailored learning resources and insights.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-spring-leaves-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-spring-leaves-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-spring-leaves-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={teacherIMG}
            alt="Educators collaborating"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-spring-leaves-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
      <div className="mt-20 flex items-center justify-center gap-x-6">
          <Link
            to="/news-letter"
            className="rounded-md bg-spring-leaves-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-spring-leaves-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-spring-leaves-600"
          >
            Join Wait list!
          </Link>
          <a href="/faq" className="text-sm font-semibold leading-6 text-spring-leaves-800">
            Learn more <span aria-hidden="true">→</span>
          </a>
        </div>
    </div>
  )
}
