import React from 'react';
import { Link } from 'react-router-dom';

const CTAComponent = () => {
    return (
        <div className="bg-gradient-to-r from-spring-leaves-300 to-spring-leaves-600 p-8 text-white text-center h-96 w-full flex flex-col justify-center items-center">
            <h2 className="text-2xl font-bold mb-3">Elevate Learning with Berni</h2>
            <p className="mb-5">Take your teaching to the next level with our suite of creative tools.</p>
            <div className="flex">
                <Link to="/join" className="bg-white text-spring-leaves-600 font-semibold py-2 px-4 rounded mr-2 animate-pulse">Join Berni</Link>
                <Link to="/pricing" className="bg-transparent border border-white py-2 px-4 rounded">See Plans</Link>
            </div>
        </div>
    );
};

export default CTAComponent;
