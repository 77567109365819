import { GlobeAmericasIcon, EyeIcon, HandRaisedIcon, GlobeAltIcon, ComputerDesktopIcon, BellIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

const cookiePolicies = [
    {
        name: 'What are Cookies?',
        description: 'Cookies are small text files stored on your device when you visit a website. They help us remember your preferences and understand how you use our platform.',
        icon: GlobeAmericasIcon,
    },
    {
        name: 'Why We Use Cookies',
        description: 'We use cookies to offer a better browsing experience, analyze site traffic, personalize content, and serve targeted advertisements.',
        icon: EyeIcon,
    },
    {
        name: 'Consent to Use Cookies',
        description: 'By using our platform, you consent to the use of cookies unless you have disabled them in your browser settings.',
        icon: HandRaisedIcon,
    },
    {
        name: 'Third-party Cookies',
        description: 'Our platform may use third-party services that set their own cookies. These cookies are not controlled by us but allow us to provide certain functionalities.',
        icon: GlobeAltIcon,
    },
    {
        name: 'Session and Persistent Cookies',
        description: 'Session cookies are temporary and deleted when you close your browser. Persistent cookies remain until you delete them or they expire.',
        icon: ComputerDesktopIcon,
    },
    {
        name: 'Opting Out',
        description: 'You can set your browser to not accept cookies, but this may limit your ability to use the services.',
        icon: BellIcon,
    },
    {
        name: 'Changes to Cookie Policy',
        description: 'We may update our Cookie Policy from time to time. We advise you to review this policy periodically.',
        icon: QuestionMarkCircleIcon,
    },
]

export default function Cookies() {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-spring-leaves-600">Berni App - Cookie Policy</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-spring-leaves-900 sm:text-4xl">
                        How we use cookies on our platform
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {cookiePolicies.map((policy) => (
                            <div key={policy.name} className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-black">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-spring-leaves-500">
                                        <policy.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    {policy.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-black">{policy.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
