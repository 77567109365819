import React from 'react';
import ApexCharts from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { AcademicCapIcon} from '@heroicons/react/24/solid';

const shadowStyle: React.CSSProperties = {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.10), 0px 8px 16px rgba(0, 0, 0, 0.25)'
};

interface RatingData {
    month: string;
    classroomRating: number;
    stateAvgRating: number;
  }
  
  const mockRatings: RatingData[] = [
    { month: 'August', classroomRating: 78, stateAvgRating: 75 },
    { month: 'September', classroomRating: 82, stateAvgRating: 78 },
    { month: 'October', classroomRating: 86, stateAvgRating: 84 },
    { month: 'November', classroomRating: 80, stateAvgRating: 79 },
    { month: 'December', classroomRating: 84, stateAvgRating: 88 },
    { month: 'January', classroomRating: 82.5, stateAvgRating: 90 },
  ];
  
  

const Chart: React.FC = () => {
    const averageClassroomRating = mockRatings.reduce((sum, data) => sum + data.classroomRating, 0) / mockRatings.length;
// const averageStateRating = mockRatings.reduce((sum, data) => sum + data.stateAvgRating, 0) / mockRatings.length;



const chartOptions: ApexOptions = {
    chart: {
      height: 350,
      type: 'line',  // Updated type to 'line'
      zoom: {
        enabled: false
      }
    },
    colors: ['#3c5f48', '#9cb9a3'],  // Set the colors here
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories: mockRatings.map(data => data.month),
    },
    tooltip: {
      x: {
        format: 'MMMM'
      },
    },
};

  
  const chartSeries = [
    {
      name: 'Classroom Rating',
      data: mockRatings.map(data => data.classroomRating),
    },
    {
      name: 'State Avg Rating',
      data: mockRatings.map(data => data.stateAvgRating),
    },
  ];
  
  
  

  return (
    <div className="max-w-2xl w-full bg-neutral-100 rounded-lg shadow dark:bg-gray-800 p-4 md:p-6" style={shadowStyle}>
      <div className="flex justify-between mb-5">
        <div className="grid gap-4 grid-cols-2">
          <div>
          <div className="flex items-center">
          <div className="w-12 h-12 rounded-lg bg-spring-leaves-200 flex items-center justify-center mr-3">
            <AcademicCapIcon className="h-6 w-6 text-spring-leaves-800" />
          </div>
          <div>
            <h5 className="leading-none text-2xl font-bold text-gray-900 pb-1">{averageClassroomRating.toFixed(2)}</h5>
            <p className="text-sm font-normal text-gray-500">Berni Score</p>
          </div>
        </div>



          </div>
        </div>
       
      </div>
      
      <div id="line-chart">
        <ApexCharts 
          options={chartOptions} 
          series={chartSeries} 
          height={350} 
        />
      </div>
      
      <div className="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between mt-2.5">
        <div className="pt-5">      
          <a href="/detailed-report" className="text-spring-leaves-500 hover:text-spring-leaves-700">View Detailed Report</a>
        </div>
      </div>
    </div>
  );
}

export default Chart;
