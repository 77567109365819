import { useState } from 'react';
import { Switch } from '@headlessui/react';
import { Link } from 'react-router-dom';

function classNames(...classes: (string | undefined | null | boolean)[]) {
    return classes.filter(Boolean).join(' ');
}

export default function ParentOnboarding() {
  const [agreed, setAgreed] = useState(false);

  return (
    <div className="isolate px-6 py-24 sm:py-32 lg:px-8 animate-fadeIn">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-spring-leaves-700 sm:text-4xl">Parent Onboarding</h2>
        <p className="mt-2 text-lg leading-8 text-spring-leaves-700">
          Please provide the following details to complete your onboarding process.
        </p>
      </div>
      <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <label htmlFor="berni-id" className="block text-sm font-semibold leading-6 text-spring-leaves-900">
              Student's Berni ID
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="berni-id"
                id="berni-id"
                placeholder="BID123456"
                className="input input-bordered w-full"
              />
            </div>
            <p className="mt-2 text-xs text-spring-leaves-600">Ask the teacher if you don't know.</p>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-spring-leaves-900">
              Phone Number
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="phone-number"
                id="phone-number"
                placeholder="123-456-7890"
                className="input input-bordered w-full"
              />
            </div>
            <p className="mt-2 text-xs text-spring-leaves-600">Teachers can use this to reach out to you.</p>
          </div>
          <div>
            <label htmlFor="child-first-name" className="block text-sm font-semibold leading-6 text-spring-leaves-900">
              Child's First name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="child-first-name"
                id="child-first-name"
                autoComplete="given-name"
                placeholder="Ella"
                className="input input-bordered w-full"
              />
            </div>
          </div>
          <div>
            <label htmlFor="child-last-name" className="block text-sm font-semibold leading-6 text-spring-leaves-900">
              Child's Last name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="child-last-name"
                id="child-last-name"
                autoComplete="family-name"
                placeholder="Smith"
                className="input input-bordered w-full"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="classroom-code" className="block text-sm font-semibold leading-6 text-spring-leaves-900">
              Classroom Code
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="classroom-code"
                id="classroom-code"
                placeholder="example: ABww423"
                className="input input-bordered w-full"
              />
            </div>
            <p className="mt-2 text-xs text-spring-leaves-600">Ask the teacher if you don't know.</p>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="profile-pic" className="block text-sm font-semibold leading-6 text-spring-leaves-900">
                Upload Profile Picture
            </label>
            <div className="mt-2.5 relative border-dashed border-2 border-spring-leaves-300 rounded-md p-4 text-center hover:bg-spring-leaves-50 transition-all duration-300">
                <input
                    type="file"
                    name="profile-pic"
                    id="profile-pic"
                    className="cursor-pointer absolute top-0 left-0 w-full h-full opacity-0"
                    onChange={(e) => {
                        // Handle file change, e.g., preview the image or update state
                    }}
                />
                <div className="text-spring-leaves-600">
                    <p className="mb-2">Drag & Drop your image here</p>
                    <p className="text-xs">or</p>
                    <p className="text-sm font-semibold">Browse for a file</p>
                </div>
            </div>
          </div>
          <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
            <div className="flex h-6 items-center">
              <Switch
                checked={agreed}
                onChange={setAgreed}
                className={classNames(
                  agreed ? 'bg-spring-leaves-500' : 'bg-gray-200',
                  'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-spring-leaves-300 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-spring-leaves-600'
                )}
              >
                <span className="sr-only">Agree to policies</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    agreed ? 'translate-x-3.5' : 'translate-x-0',
                    'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-spring-leaves-300 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </div>
            <Switch.Label className="text-sm leading-6 text-spring-leaves-700">
              By selecting this, you agree to our{' '}
              <Link to="/terms" className="font-semibold text-spring-leaves-500">
                terms and conditions
              </Link>
              .
            </Switch.Label>
          </Switch.Group>
        </div>
        <div className="mt-10">
          <Link 
  to="/parent-home"
  className="block w-full rounded-md bg-spring-leaves-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-spring-leaves-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-spring-leaves-600"
>
  Submit
</Link>
        </div>
      </form>
    </div>
  );
}
