import { Link } from 'react-router-dom';
import { VideoCameraIcon, NewspaperIcon } from '@heroicons/react/24/solid';

export default function HeroSection() {
  return (
    <div className="bg-white flex flex-col justify-center w-full min-h-screen lg:min-h-fit">
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="text-center w-full">
          <div className="mb-4">
            <div className="rounded-full px-3 py-1 text-xs leading-4 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20 inline-block">
              Announcing our next round of funding.{' '}
              <Link to="/learn-more" className="font-semibold text-spring-leaves-700">
                Learn more <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </div>
          
          <h1 className="text-2xl sm:text-md md:text-2xl lg:text-4xl font-semibold tracking-tight text-spring-leaves-900">
            <span className="block ">Crafting Tomorrow's Lessons,</span>
            <span className="block ">Today's Way.</span>
          </h1>

          <p className="mt-4 text-base text-left sm:text-lg md:text-xl leading-8 text-spring-leaves-600 px-2 sm:px-0 lg:px-2 2xl:px-10 max-w-2xl">
            Berni offers a smart, AI-enhanced platform for K-12 educators, crafting personalized and printable worksheets that adapt to each student's learning journey. Our service provides deep analytical insights for teachers and parents, ensuring every child's educational progress is understood and nurtured.
          </p>

          <div className="mt-6 flex flex-col sm:flex-row justify-center gap-3 px-2 sm:px-0">
            <Link
              to="/news-letter"
              className="rounded-md bg-spring-leaves-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-spring-leaves-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-spring-leaves-600 w-full sm:w-auto"
            >
                Join Wait List!
            </Link>
            <Link
              to="/blog"
              className="flex items-center justify-center gap-x-2 rounded-md border-2 border-spring-leaves-200 px-3.5 py-2.5 text-sm font-semibold text-spring-leaves-600 shadow-sm hover:bg-spring-leaves-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-spring-leaves-600 w-full sm:w-auto"
            >
              <NewspaperIcon className="h-5 w-5 text-spring-leaves-800"/>
              Read Blog
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
