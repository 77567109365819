import { Link } from 'react-router-dom';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="mt-16 footer p-10 bg-spring-leaves-950 text-white">
      <nav>
        <header className="footer-title">Services</header>
        <Link to="/for-educators" className="link link-hover">Educators</Link>
        <Link to="/for-parents" className="link link-hover">Parents</Link>
        <Link to="/pricing" className="link link-hover">Pricing</Link>
        <Link to="/contact" className="link link-hover">Contact</Link>
        <Link to="/faq" className="link link-hover">FAQ</Link>
      </nav>
      <nav>
        <header className="footer-title">Company</header>
        <Link to="/" className="link link-hover">Jobs</Link> 
      </nav>
      <nav>
        <header className="footer-title">Legal</header>
        <Link to="/terms" className="link link-hover">Terms of use</Link> 
        <Link to="/privacy" className="link link-hover">Privacy policy</Link>
        <Link to="/cookies" className="link link-hover">Cookie policy</Link> 
      </nav>
      <form>
        <header className="footer-title">Newsletter</header>
        <fieldset className="form-control w-80">
          <label className="label">
            <span className="label-text">Enter your email address</span>
          </label>
          <div className="relative">
            <input type="text" placeholder="username@site.com" className="input input-bordered w-full pr-16" />
            <button className="btn btn-primary absolute top-0 right-0 rounded-l-none">Subscribe</button>
          </div>
        </fieldset>
      </form>
      <div className="mt-4 border-t pt-4 border-white/10">
         <p className="text-center text-sm">©{currentYear} Berni AI. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
