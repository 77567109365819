import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

const faqs = [
  {
    question: "What is Berni AI and how does it benefit educators?",
    answer: "Berni AI is a transformative platform that uses artificial intelligence to redefine the educational experience. It provides educators with adaptive, AI-crafted worksheets, offering a tailored learning journey for every student. This ensures a more personalized and effective teaching approach."
  },
  {
    question: "How does the adaptive learning mechanism work?",
    answer: "Berni AI captures student responses and scores. Based on this data, the platform automatically adjusts the difficulty of the next worksheet, ensuring that students are always challenged at the right level."
  },
  {
    question: "How are gamified elements integrated?",
    answer: "Berni AI incorporates gamified elements like badges, leaderboards, and challenges to make learning more engaging for students. This not only motivates students but also makes the learning process more enjoyable."
  },
  {
    question: "Can parents track their child's progress?",
    answer: "Absolutely! Parents have a dedicated dashboard that provides real-time data and insights on their child’s academic trajectory. This ensures they're always in the loop about their child's academic progress."
  },
  {
    question: "How secure is the data on Berni AI?",
    answer: "Data security is a top priority for Berni AI. We use basic encryption for user data and are compliant with GDPR. Our terms and conditions and privacy policy provide detailed information on data use."
  },
  {
    question: "What makes Berni AI different from other ed-tech tools?",
    answer: "Berni AI is not just another ed-tech tool. It's a holistic ecosystem where educators, students, and parents come together. With AI-driven personalization and a blend of online and printable resources, it ensures a comprehensive approach to education."
  },
  {
    question: "Are there any costs associated with using Berni AI?",
    answer: "Berni AI operates on a freemium model. Individual educators can access core features at no cost. There are options to upgrade for enhanced capabilities. For schools or districts, we offer tailored licensing packages with potential platform customizations."
  },
  {
    question: "How does Berni AI ensure the quality of generated worksheets?",
    answer: "Leveraging the GPT-4 API, Berni AI ensures state-of-the-art worksheet creation. Additionally, teachers can provide feedback on the quality and relevance of generated worksheets, allowing continuous improvement."
  }
];

export default function FAQSection() {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-spring-leaves-900 sm:text-4xl">Frequently Asked Questions</h2>
          <p className="mt-2 text-lg leading-8 text-spring-leaves-600">
            Here are some common questions about Berni AI.
          </p>
        </div>
        <div className="mt-12">
          {faqs.map((faq, idx) => (
            <div key={idx} className="border-b border-spring-leaves-200 last:border-0">
              <button
                className="w-full text-left px-6 py-4 flex justify-between items-center"
                onClick={() => setActiveIndex(idx === activeIndex ? null : idx)}
              >
                <span className="font-semibold text-spring-leaves-900">{faq.question}</span>
                {idx === activeIndex ? (
                  <ChevronUpIcon className="h-6 w-6 text-spring-leaves-600" />
                ) : (
                  <ChevronDownIcon className="h-6 w-6 text-spring-leaves-600" />
                )}
              </button>
              {idx === activeIndex && (
                <div className="px-6 pb-4 text-spring-leaves-600">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
