import React from 'react';
import ParentSidebar from '../components/parent-home/ParentSidebar';
import ParentChart from '../components/parent-home/ParentChart';
import ParentGraph from '../components/parent-home/ParentGraph';
import ParentPieChart from '../components/parent-home/ParentPiechart';
import ChildSummary from '../components/parent-home/ChildSummary';


const shadowStyle: React.CSSProperties = {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.10), 0px 8px 16px rgba(0, 0, 0, 0.08)'
};


const ParentHome: React.FC = () => {
    return (
        <div className="flex flex-col min-h-screen">

            {/* Content */}
            <div className="flex flex-1">
                {/* Sidebar */}
                <ParentSidebar />
                {/* Main content */}
                <div className="flex flex-col w-full p-4">
                <h2 className="font-semibold text-xl text-spring-leaves-600 mb-2">David's Overview</h2>
                    <div className=' max-w-screen-2xl w-full mx-auto mt-6'>
                        <div className="flex flex-col md:flex-row gap-4 justify-center"> 
                            <ParentChart/>
                            <ParentGraph/>
                            <ParentPieChart/>
                        </div>
                        
                        
                    </div>           
                    <div className=' max-w-screen-2xl w-full mx-auto mt-6' style={shadowStyle}><ChildSummary/></div>        
                </div>
            </div>
        </div>

    );
};

export default ParentHome;


