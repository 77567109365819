// WorksheetFooter.tsx

import React from 'react';
import berniDogImg from '../../../assets/Berni-dog.png';

const WorksheetFooter = () => {
  return (
    <div className="footer absolute bottom-2 w-full justify-center items-center text-xs text-gray-500 p-1 flex" style={{ gap: '3px' }}>
        <img src={berniDogImg} alt="Berni Logo" className="w-auto h-3" />
        <div>Crafted with Berni AI</div>
    </div>
  );
};

export default WorksheetFooter;
