import React from 'react';
import Sidebar from "../components/teacher-home/Sidebar";
import Cards from "../components/teacher-home/Cards";
import Analytics from "../components/teacher-home/Analytics";
import Roster from '../components/teacher-home/Roster';

const TeacherHome: React.FC = () => {
    return (
        <div className="flex flex-col min-h-screen">

            {/* Content */}
            <div className="flex flex-1">
                {/* Sidebar */}
                <Sidebar />
                {/* Main content */}
                <div className="flex flex-col p-4 md:p-8 w-full"> 
                    <h2 className="font-semibold text-lg text-spring-leaves-600 mb-4 mt-2">Classroom Overview</h2>
                    <Cards />
                    <Analytics />
                    <Roster />
                </div>
            </div>
        </div>
    );
};

export default TeacherHome;
