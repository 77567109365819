import React from 'react';
import { ArrowUpIcon, ChevronDownIcon, ArrowTrendingUpIcon, ArrowRightCircleIcon } from '@heroicons/react/24/solid';
import ApexCharts from 'react-apexcharts';


const shadowStyle: React.CSSProperties = {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.10), 0px 8px 16px rgba(0, 0, 0, 0.08)'
};
const barColors = [
  '#9cb9a3', // medium green
  '#588466', // dark green
  '#3c5f48', // darker green
];
// Mock data
interface ScoreData {
  day: string;
  score: number;
}

const mockScores: ScoreData[] = [
  { day: 'Monday', score: 82 },
  { day: 'Tuesday', score: 85 },
  { day: 'Wednesday', score: 88 },
  { day: 'Thursday', score: 74},
  { day: 'Friday', score: 80 },
];

const ParentGraph: React.FC = () => {
  const averageScore = mockScores.reduce((sum, data) => sum + data.score, 0) / mockScores.length;

  const chartOptions = {
    chart: {
      height: 350
    },
    colors: barColors,
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: mockScores.map(data => data.day)
    },
    yaxis: {
      title: {
        text: "Scores"
      }
    }
  };

  const chartSeries = [{
    name: 'Score',
    data: mockScores.map(data => data.score)
  }];

  return (
    <div className="max-w-lg w-full bg-neutral-100 rounded-lg shadow p-4 md:p-6" style={shadowStyle}>
      <div className="flex justify-between pb-4 mb-4 border-b border-gray-200">
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-lg bg-spring-leaves-200 flex items-center justify-center mr-3">
            <ArrowTrendingUpIcon className="h-6 w-6 text-spring-leaves-800" />
          </div>
          <div>
            <h5 className="leading-none text-2xl font-bold text-gray-900 pb-1">{averageScore.toFixed(2)}%</h5>
            <p className="text-sm font-normal text-gray-500">Students Avg Weekly Score</p>
          </div>
        </div>
        <div>
          <span className="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md">
            <ArrowUpIcon className="h-4 w-4 mr-1" />
            5% increase
          </span>
        </div>
      </div>

      <div id="column-chart">
        <ApexCharts 
          options={chartOptions} 
          series={chartSeries} 
          type="bar" 
          height={350} 
        />
      </div>

      <div className="grid grid-cols-1 items-center border-gray-200 border-t justify-between">
        <div className="flex justify-between items-center pt-5">
          <button
            id="dropdownDefaultButton"
            className="text-sm font-medium text-gray-500 hover:text-gray-900 text-center inline-flex items-center"
            type="button">
            Last 7 days
            <ChevronDownIcon className="h-4 w-4 ml-1" />
          </button>
          <a
            href="/teacher-home"
            className="text-sm inline-flex items-center rounded-lg text-spring-leaves-500 hover:text-spring-leaves-700 px-3 py-2">
            Monthly Report
            <ArrowRightCircleIcon className="h-4 w-4 ml-1" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ParentGraph;
