import React from 'react';
import mathAdditionImage from '../../assets/Berni-ws-1.png';
import geometryIntroImage from '../../assets/Berni-ws-2.png';
import advancedCalculusImage from '../../assets/Berni-ws-3.png';

// Example worksheet data (replace with real data)
const worksheets = [
  {
    id: 1,
    title: 'Multiplication Worksheet',
    href: '#',
    imageSrc: mathAdditionImage, // Replace with appropriate image URL for Multiplication
    imageAlt: 'Multiplication Worksheet Example.',
    description: 'Exploring basic to advanced multiplication concepts.',
  },
  {
    id: 2,
    title: 'Counting Shapes Worksheet',
    href: '#',
    imageSrc: advancedCalculusImage , // Replace with appropriate image URL for Counting Shapes
    imageAlt: 'Counting Shapes Worksheet Example.',
    description: 'Identifying and counting shapes in various configurations.',
  },
  {
    id: 3,
    title: 'Advanced Multiplication Worksheet',
    href: '#',
    imageSrc: geometryIntroImage, // Replace with appropriate image URL for Advanced Multiplication
    imageAlt: 'Advanced Multiplication Worksheet Example.',
    description: 'Challenging multiplication problems for advanced learners.',
  },
  {
    id: 4,
    title: 'Basic Counting Shapes Worksheet',
    href: '#',
    imageSrc: advancedCalculusImage, // Replace with appropriate image URL for Basic Counting Shapes
    imageAlt: 'Basic Counting Shapes Worksheet Example.',
    description: 'Simple exercises for counting and recognizing basic shapes.',
  }
  // ... add more worksheets as needed
];

export default function WorksheetDemo() {
  return (
    <div className="bg-gray-100 min-w-full">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="text-2xl font-bold tracking-tight text-spring-leaves-700">Worksheets made with Berni</h2>
        <p className="mt-1 text-md text-gray-600">Enjoy unparalleled flexibility in formatting and designing worksheets that truly resonate with your students.</p>

        <div className="mt-10 grid grid-cols-2 gap-x-4 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xl:gap-x-4">
          {worksheets.map((worksheet, index) => (
            <div key={index} className="group relative">
              <div className="w-full overflow-hidden rounded-md bg-gray-200 lg:h-[400px] lg:w-auto">
                <img
                  src={worksheet.imageSrc}
                  alt={worksheet.imageAlt}
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div className="mt-4">
                <h3 className="text-sm text-gray-700">{worksheet.title}</h3>
                <p className="mt-1 text-sm text-gray-500">{worksheet.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}