import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import TeacherNavbar from './components/teacher-home/TeacherNavbar';
import Footer from './components/Footer';
import ForEducators from './containers/ForEducators';
import ForParents from './containers/ForParents';
import Contact from './containers/Contact';
import FAQSection from './containers/FAQ';
import { SignIn, Register } from './components/Signin';
import Pricing from './containers/Pricing';
import Onboarding from './containers/Onboarding';
import Newsletter from './components/NewsLetter';
import About from './containers/About';
import TeacherHome from './containers/TeacherHome';
import Terms from './containers/Terms';
import Privacy from './containers/Privacy';
import Cookies from './containers/Cookies';
import ParentHome from './containers/ParentHome';
import MathWs from './components/work-sheets/MathWs';
import LandingPage from './containers/LandingPage';
import WorksheetGen from './containers/WorksheetGen';
import Blog from './containers/Blog/Blogs';
import Article1 from './containers/Blog/Articles/Article-1';

function App() {
  return (
    <Router>
      
      <PageWrapper>
        <div className="flex-grow">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/for-educators" element={<ForEducators />} />
          <Route path="/for-parents" element={<ForParents />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<FAQSection />} />
          <Route path="/log-in" element={<SignIn />} />
          <Route path="/join" element={<Register />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/news-letter" element={<Newsletter />} />
          <Route path="/learn-more" element={<About />} />
          <Route path="/teacher-home" element={<TeacherHome />} />
          <Route path="/parent-home" element={<ParentHome />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/worksheet" element={<WorksheetGen />} />
          <Route path="/math" element={<div className='w-3/4'><MathWs /></div>} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/article-1" element={<Article1 />} />
          
        </Routes>
        </div>
        
        <Footer />
      </PageWrapper>
    </Router>
  );
}

function PageWrapper({ children }: { children: React.ReactNode }) {
  let location = useLocation();
  return (
    <div className="App flex flex-col min-h-screen">
      {location.pathname === '/teacher-home' || location.pathname === '/parent-home' || location.pathname === '/worksheet' ? <TeacherNavbar /> : <Navbar />}
      {children}
    </div>
  );
}

export default App;
