import React from 'react';
import GraphComponent from './Graph';
import Chart from './Charts';
import TeacherPieChart from './TeacherPieChart';
import { ChartPieIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import Summary from './Summary';

const Analytics: React.FC = () => {
    return (
        <div className="flex justify-center mt-2 mb-8">
            <div className="flex flex-col md:flex-row max-w-screen-2xl w-full mx-auto ">
                {/* Bigger rectangle */}
                <div className="flex-none w-full h-auto bg-white p-2 md:p-2 rounded-lg mb-6 md:mb-0">
                    <div className="mb-6">
                        <div className="flex items-center mb-2">
                            <ChartPieIcon className="h-6 w-6 text-spring-leaves-600 mr-2" />
                            <h1 className="font-semibold text-xl text-spring-leaves-600">Analytics</h1>
                        </div>
                    </div>
                    {/* Desktop view */}
                    <div className="hidden md:flex flex-row gap-4">
                        <Chart/>
                        <GraphComponent/>
                        <TeacherPieChart/>
                    </div>
                    {/* Mobile view with swipe feature */}
                    <div className="md:hidden carousel w-full mb-0">
                        <div className="carousel-item w-full mx-4 py-4">
                            <Chart/>
                        </div>
                        <div className="carousel-item w-full mx-4 py-4">
                            <GraphComponent/>
                        </div>
                        <div className="carousel-item w-full mx-4 py-4">
                            <TeacherPieChart/>
                        </div>
                    </div>
                    <div className="md:hidden flex justify-center items-center w-full py-2 gap-1">
                        <span className="text-zinc-400 right-pulse-animation">Swipe</span>
                        <ChevronDoubleRightIcon className="h-6 w-6 text-zinc-400 right-pulse-animation" />
                    </div>
                    <Summary/>
                </div>
            </div>
        </div>
    );
};

export default Analytics;

// Add the style for the swipe animation
const styles = `
    .right-pulse-animation {
        animation: rightPulse 1.5s infinite;
    }

    @keyframes rightPulse {
        0% {
            transform: translateX(0px);
        }
        50% {
            transform: translateX(5px);
        }
        100% {
            transform: translateX(0px);
        }
    }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
