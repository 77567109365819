import React from 'react';
import HeroSection from '../components/landing-page/HeroSection';
import Carousel from '../components/landing-page/Carousel';
import Features from '../components/landing-page/Features';
import TestimonySection from '../components/landing-page/Testimonials';
import WorksheetDemo from '../components/landing-page/WorksheetDemo';
import CTAComponent from '../components/landing-page/CTA';

const LandingPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen w-full">
      {/* Content of Landing Page */}
      <main className=' w-full flex flex-col md:flex-row justify-between items-start md:items-center space-y-2 md:space-x-2 lg:space-x-0 lg:w-11/12 lg:mb-12' style={{ minHeight: '80vh' }}>
        {/* Main content such as Hero section */}
        <div className="w-full px-4 sm:w-4/5 mx-auto">
          <HeroSection/>
        </div>

        
        {/* Gallery component that hides on mobile */}
        <div className="hidden md:block md:w-4/5 lg:w-full">
          {/* <Gallery/> */}
          <Carousel/>
        </div>
      </main>
      <section className='w-full'>
        <Features/>
      </section>
     
      <WorksheetDemo/>
      <CTAComponent/>
       <TestimonySection/>
    </div>
  );
};

export default LandingPage;
