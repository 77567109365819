import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon, DocumentTextIcon, CalendarIcon, QuestionMarkCircleIcon} from '@heroicons/react/24/solid';

const customShadow = {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, .35), 0px 8px 16px rgba(0, 0, 0, 0.08)'
};

interface CardProps {
    title: string;
    to: string;
    gradient: string;
    message: string;
    icon: JSX.Element;
}

const Card: React.FC<CardProps> = ({ title, to, gradient, message, icon }) => {
    return (
        <Link 
            to={to} 
            className={`transition-transform transform hover:scale-105 ${gradient} rounded-lg overflow-hidden mx-0 md:mx-5 mb-4 flex-1 min-w-0 md:first:ml-0 md:last:mr-0 md:w-auto w-3/4 mx-auto p-4`}
            style={customShadow}
        >
            <div className="p-6">
                <div className="flex items-center justify-center mb-4">
                    <div className="mr-4">{React.cloneElement(icon, { className: "h-8 w-8 text-white" })}</div>
                    <h2 className="font-bold text-2xl text-white">{title}</h2>
                </div>
                <p className="text-gray-200 text-lg">{message}</p>
            </div>
            <div className="px-6 pt-4 pb-2 flex justify-end items-center">
                <span className="text-gray-200 text-lg mr-2">Create</span>
                <ChevronRightIcon className="h-6 w-6 text-white"/>
            </div>
        </Link>
    );
};

const Cards: React.FC = () => {
    const [activeCard, setActiveCard] = useState(0);

    return (
        <div className="flex flex-col md:flex-row justify-center items-center mt-2 mb-8" >
            <div className="hidden md:flex flex-row flex-wrap justify-center md:justify-between max-w-screen-2xl w-full mx-auto gap-x-5">
                <Card 
                    title="Worksheet" 
                    to="/worksheet" 
                    gradient="bg-gradient-to-r from-spring-leaves-500 to-spring-leaves-700" 
                    message="Generate worksheets using the power of AI."
                    icon={<DocumentTextIcon />}
                />
                <Card 
                    title="Studyplan" 
                    to="/studyplan" 
                    gradient="bg-gradient-to-r from-spring-leaves-300 to-spring-leaves-500" 
                    message="Create today's or the week's study plan with the help of AI."
                    icon={<CalendarIcon />}
                />
                <Card 
                    title="Quiz" 
                    to="/quiz" 
                    gradient="bg-gradient-to-r from-spring-leaves-500 to-spring-leaves-700" 
                    message="Design quizzes tailored to your needs with AI assistance."
                    icon={<QuestionMarkCircleIcon />}
                />
            </div>
            <div className="md:hidden carousel w-full mb-0">
                <div id="item1" className={`carousel-item w-full mx-4 py-4 ${activeCard === 0 ? 'active' : ''}`}>
                    <Card 
                        title="Worksheet" 
                        to="/worksheet" 
                        gradient="bg-gradient-to-r from-spring-leaves-500 to-spring-leaves-700" 
                        message="Generate worksheets using the power of AI."
                        icon={<DocumentTextIcon />}
                    />
                </div>
                <div id="item2" className={`carousel-item w-full mx-4 py-4 ${activeCard === 1 ? 'active' : ''}`}>
                <Card 
                    title="Studyplan" 
                    to="/studyplan" 
                    gradient="bg-gradient-to-r from-spring-leaves-300 to-spring-leaves-500" 
                    message="Create today's or the week's study plan with the help of AI."
                    icon={<CalendarIcon />}
                />
                </div>
                <div id="item3" className={`carousel-item w-full mx-4 py-4 ${activeCard === 2 ? 'active' : ''}`}>
                <Card 
                    title="Quiz" 
                    to="/quiz" 
                    gradient="bg-gradient-to-r from-spring-leaves-500 to-spring-leaves-700" 
                    message="Design quizzes tailored to your needs with AI assistance."
                    icon={<QuestionMarkCircleIcon />}
                />
                </div>
            </div>
            <div className="md:hidden flex justify-center items-center w-full py-2 gap-1">
                <span className={`text-zinc-400 ${activeCard === 0 ? 'active' : ''}`} style={{ color: activeCard === 0 ? 'red-200' : '' }}>●</span>
                <span className={`text-zinc-400 ${activeCard === 1 ? 'active' : ''}`} style={{ color: activeCard === 1 ? 'green' : '' }}>●</span>
                <span className={`text-zinc-400 ${activeCard === 2 ? 'active' : ''}`} style={{ color: activeCard === 2 ? 'green' : '' }}>●</span>
            </div>
        </div>
    );
};

export default Cards;

const styles = `
    .right-pulse-animation {
        animation: rightPulse 1.5s infinite;
    }

    @keyframes rightPulse {
        0% {
            transform: translateX(0px);
        }
        50% {
            transform: translateX(5px);
        }
        100% {
            transform: translateX(0px);
        }
    }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
