import React from 'react';
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, LightBulbIcon } from '@heroicons/react/24/outline';

interface SummaryItem {
  title: string;
  value: string | number;
}

interface FeedbackCardProps {
  label: string;
  message: string;
  Icon: React.ElementType;
  bgColor: string;
  textColor: string;
}

const FeedbackCard: React.FC<FeedbackCardProps> = ({ label, message, Icon, bgColor, textColor }) => {
  return (
    <div className={`p-4 rounded-lg shadow-md flex items-center ${bgColor} text-center`}>
      <Icon className={`h-8 w-8 ${textColor} mr-12`} />
      <div className="text-left">
        <span className={`font-semibold ${textColor}`}>{label}</span>
        <p className={`${textColor} text-sm`}>{message}</p>
      </div>
    </div>
  );
};

const Summary: React.FC = () => {
  const summaryItems: SummaryItem[] = [
    {
      title: 'Best Topic',
      value: 'Multiplication (95%)',
    },
    {
      title: 'Needs Improvement',
      value: 'Division (73%)',
    },
    {
      title: 'Classroom vs State',
      value: '-5.2%',
    },
    {
      title: 'Projected EOY Berni Score',
      value: '85.7',
    },
  ];

  return (
    <div className="p-4 rounded-lg space-y-6 bg-white shadow-lg">
      <h2 className="text-lg font-semibold text-spring-leaves-800 text-center">Performance Summary</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {summaryItems.map((item, index) => (
          <div key={index} className="p-3 rounded-lg flex flex-col items-center justify-center bg-gray-100 shadow-sm text-center">
            <h3 className="text-md font-semibold text-gray-700">{item.title}</h3>
            <p className="text-lg text-spring-leaves-700">{item.value}</p>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
        <FeedbackCard
          label="Good Job"
          message="Students are excelling in addition and subtraction."
          Icon={CheckCircleIcon}
          bgColor="bg-green-100"
          textColor="text-green-800"
        />
        <FeedbackCard
          label="Needs Attention"
          message="Students are finding division problems challenging."
          Icon={ExclamationCircleIcon}
          bgColor="bg-yellow-100"
          textColor="text-yellow-800"
        />
        <FeedbackCard
          label="Alert"
          message="Classroom performance in division is below the state average."
          Icon={InformationCircleIcon}
          bgColor="bg-red-100"
          textColor="text-red-800"
        />
        <FeedbackCard
          label="Berni Tip"
          message="Consider revising division strategies to improve understanding."
          Icon={LightBulbIcon}
          bgColor="bg-blue-100"
          textColor="text-blue-800"
        />
      </div>
      <div className="text-center mt-4">
        <button className="inline-block bg-spring-leaves-700 hover:bg-spring-leaves-600 text-white font-bold py-2 px-6 rounded-md">
          Generate
        </button>
        <p className="text-gray-500 text-sm font-light mt-2">
          Generate a worksheet based on classroom report.
        </p>
      </div>
    </div>
  );
};

export default Summary;
