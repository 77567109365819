import React, { useState, useEffect } from 'react';
import parentDashboard from '../../assets/parent-dashboard.png';
import teacherDashboard from '../../assets/teacher-dash-new.png';
import Worksheets from '../../assets/WS-sample.png';

interface GalleryItem {
    imgSrc: string;
    title: string;
    description: string;
}

const galleryItems: GalleryItem[] = [
    {
        imgSrc: teacherDashboard,
        title: "Teacher Dashboard",
        description: "Monitor student engagement and track class performance with a comprehensive dashboard.",
    },
    {
        imgSrc: Worksheets,
        title: "Dynamic Worksheets",
        description: "Craft interactive and personalized worksheets to enhance the learning experience.",
    },
    {
        imgSrc: parentDashboard,
        title: "Student Progress",
        description: "Keep informed with detailed reports on your child's progress and milestones.",
    },
    // ... add more items as needed
];

const Carousel: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex === galleryItems.length - 1 ? 0 : prevIndex + 1));
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div id="default-carousel" className="relative w-full" data-carousel="slide">
            <div className="text-center py-4 mt-4 mb-2">
                <h2 className="text-spring-leaves-600 text-md md:text-md font-semibold">{galleryItems[activeIndex].title}</h2>
                <p className="text-base md:text-sm italic text-spring-leaves-800">{galleryItems[activeIndex].description}</p>
            </div>


           <div className="relative h-auto overflow-hidden rounded-lg md:h-[550px]">
    {galleryItems.map((item, index) => (
        <div key={index} className={`${activeIndex === index ? 'block' : 'hidden'} transition-opacity duration-700 ease-in-out`} data-carousel-item>
            <img src={item.imgSrc} className={`w-full h-auto ${activeIndex === index ? 'opacity-100' : 'opacity-0'}`} alt={item.title} />
        </div>
    ))}
</div>

        </div>
    );
};

export default Carousel;
