// Import React (assuming React environment)
import React from 'react';

const callouts = [
    {
        name: 'Berni AI Launches AI-Powered Worksheet Generation Product',
        description: 'Learn about Berni AI\'s latest product that uses AI to generate worksheets.',
        imageSrc: 'https://images.unsplash.com/photo-1560785496-321917f24016?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        imageAlt: 'AI-powered worksheet generation',
        href: '/blog/article-1',
    },
    {
        name: 'Berni AI Funding Round',
        description: 'Exciting news about Berni AI\'s new funding round',
        imageSrc: 'https://images.unsplash.com/photo-1541746972996-4e0b0f43e02a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80',
        imageAlt: 'Berni AI office.',
        href: '/learn-more',
    },
 
    // ... other callouts
];


export default function Blog() {
  return (
    <div className=""> {/* Background color from palette */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
          <h2 className="text-2xl font-bold text-left mb-6 text-[#213328]">Blogs</h2> {/* Text color from palette */}

          <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
            {callouts.map((callout) => (
              <div key={callout.name} className="group relative">
                <div className="relative h-80 w-full overflow-hidden rounded-lg bg-[#e1eae2] sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                  <img
                    src={callout.imageSrc}
                    alt={callout.imageAlt}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <h3 className="mt-6 text-sm text-[#304c3a]">
                  <a href={callout.href}>
                    <span className="absolute inset-0" />
                    {callout.name}
                  </a>
                </h3>
                <p className="text-base font-semibold text-[#213328]">{callout.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
