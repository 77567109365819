interface TopicItem {
  topic: string; 
  format: string;
  quantity: number;
}

interface Problem {
  num1: number;
  num2: number;
  operator: string;
  format: string; // Include format here
}

const getOperator = (topic: string): string => {
  switch (topic) {
    case 'addition': return '+';
    case 'subtraction': return '-';
    case 'multiplication': return '×';
    case 'division': return '÷';
    // Add more cases as needed
    default: return '?';
  }
};

const generateMathProblems = (topics: TopicItem[]): Problem[] => {
  let problems: Problem[] = [];

  topics.forEach(topicItem => {
    for (let i = 0; i < topicItem.quantity; i++) {
        const num1 = Math.floor(Math.random() * 88) + 2; // random number between 2 and 99
        const num2 = Math.floor(Math.random() * 198) + 1;  // random number between 2 and 9
        problems.push({ 
            num1, 
            num2, 
            operator: getOperator(topicItem.topic),
            format: topicItem.format // Assign format from TopicItem
        });
    }
  });

  return problems;
};

export default generateMathProblems;
