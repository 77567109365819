import React, { useState } from 'react';
import { UserIcon, UserGroupIcon } from '@heroicons/react/24/outline';

const Toggle = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <label className='themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-lg border border-spring-leaves-200 my-4 mx-auto' style={{ width: 'fit-content' }}>
        <input
          type='checkbox'
          className='sr-only'
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <button
            className={`flex items-center space-x-[6px] rounded-tl rounded-bl py-2 px-[18px] text-sm font-medium transition-colors duration-300 ${
            !isChecked ? 'text-primary bg-spring-leaves-200' : 'text-body-color bg-primary'
          }`}
          onClick={handleCheckboxChange}
        >
          <UserIcon className='h-4 w-4 mr-[6px]' />
          Standard
        </button>
        <button
          className={`flex items-center space-x-[6px] rounded-tr rounded-br py-2 px-[18px] text-sm font-medium transition-colors duration-300 ${
            isChecked ? 'text-primary bg-spring-leaves-200' : 'text-body-color bg-primary'
          }`}
          onClick={handleCheckboxChange}
        >
          <UserGroupIcon className='h-4 w-4 mr-[6px]' />
          Adaptive
        </button>
      </label>
    </>
  );
};

export default Toggle;
