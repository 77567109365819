import { useState } from 'react';
import { Switch } from '@headlessui/react';
import { Link } from 'react-router-dom';

function classNames(...classes: (string | undefined | null | boolean)[]) {
    return classes.filter(Boolean).join(' ');
}

export default function TeacherOnboarding() {
  const [agreed, setAgreed] = useState(false);

  return (
    <div className="isolate px-6 py-24 sm:py-32 lg:px-8 animate-fadeIn">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-spring-leaves-700 sm:text-4xl">Teacher Onboarding</h2>
        <p className="mt-2 text-lg leading-8 text-spring-leaves-700">
          Please provide the following details to complete your onboarding process.
        </p>
      </div>
      <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-spring-leaves-900">
              First name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                placeholder="John"
                className="input input-bordered w-full"
              />
            </div>
          </div>
          <div>
            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-spring-leaves-900">
              Last name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="last-name"
                id="last-name"
                autoComplete="family-name"
                placeholder="Doe"
                className="input input-bordered w-full"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="school" className="block text-sm font-semibold leading-6 text-spring-leaves-900">
              School/Institution Name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="school"
                id="school"
                placeholder="Greenwood High"
                className="input input-bordered w-full"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="grade" className="block text-sm font-semibold leading-6 text-spring-leaves-900">
              Grade Level Taught
            </label>
            <div className="mt-2.5">
              <select
                id="grade"
                name="grade"
                className="select select-bordered w-full"
              >
                <option>Kindergarten</option>
                <option>1st Grade</option>
                <option>2nd Grade</option>
                <option>3rd Grade</option>
                <option>4th Grade</option>
                <option>5th Grade</option>
                <option>6th Grade</option>
                <option>7th Grade</option>
                <option>8th Grade</option>
                <option>Other</option>
                {/* ... Add other grades as needed */}
              </select>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="tech-proficiency" className="block text-sm font-semibold leading-6 text-spring-leaves-900">
              Current Tech Proficiency
            </label>
            <div className="mt-2.5">
              <select
                id="tech-proficiency"
                name="tech-proficiency"
                className="select select-bordered w-full"
              >
                <option>Beginner</option>
                <option>Intermediate</option>
                <option>Advanced</option>
              </select>
            </div>
          </div>
          <div className="sm:col-span-2">
    <label htmlFor="profile-pic" className="block text-sm font-semibold leading-6 text-spring-leaves-900">
        Upload Profile Picture
    </label>
    <div className="mt-2.5 relative border-dashed border-2 border-spring-leaves-300 rounded-md p-4 text-center hover:bg-spring-leaves-50 transition-all duration-300">
        <input
            type="file"
            name="profile-pic"
            id="profile-pic"
            className="cursor-pointer absolute top-0 left-0 w-full h-full opacity-0"
            onChange={(e) => {
                // Handle file change, e.g., preview the image or update state
            }}
        />
        <div className="text-spring-leaves-600">
            <p className="mb-2">Drag & Drop your image here</p>
            <p className="text-xs">or</p>
            <p className="text-sm font-semibold">Browse for a file</p>
        </div>
    </div>
</div>
          <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
            <div className="flex h-6 items-center">
              <Switch
                checked={agreed}
                onChange={setAgreed}
                className={classNames(
                  agreed ? 'bg-spring-leaves-500' : 'bg-gray-200',
                  'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-spring-leaves-300 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-spring-leaves-600'
                )}
              >
                <span className="sr-only">Agree to policies</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    agreed ? 'translate-x-3.5' : 'translate-x-0',
                    'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-spring-leaves-300 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </div>
            <Switch.Label className="text-sm leading-6 text-spring-leaves-700">
              By selecting this, you agree to our{' '}
              <a href="/terms" className="font-semibold text-spring-leaves-500">
                terms and conditions
              </a>
              .
            </Switch.Label>
          </Switch.Group>
        </div>
        <div className="mt-10">
          <Link 
  to="/teacher-home"
  className="block w-full rounded-md bg-spring-leaves-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-spring-leaves-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-spring-leaves-600"
>
  Submit
</Link>
        </div>
      </form>
    </div>
  );
}

