import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HomeIcon,ChatBubbleLeftRightIcon , FireIcon, DocumentTextIcon, HomeModernIcon, ArrowLeftOnRectangleIcon, ArrowRightOnRectangleIcon, PlusCircleIcon, DocumentChartBarIcon} from '@heroicons/react/24/outline';
import teacherHeadshot from '../../assets/TeacherHeadshots/teacher-3.png';


const Sidebar: React.FC = () => {
    const [isSidebarVisible, setSidebarVisible] = useState(true);

    const user = {
        name: 'Jack Duran',
        imageUrl: teacherHeadshot,
        classroomCode: 'JD-21121'
    };

    return (
        <>
            <style>
                {`
                    .right-shadow {
                        box-shadow: 10px 0 15px -3px rgba(0, 0, 0, 0.1);
                    }
                    .tooltip:hover .tooltip-text {
                        display: block;
                    }
                    .tooltip-text {
                        display: none;
                        position: absolute;
                        z-index: 10;
                        top: -30px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: #333;
                        color: #fff;
                        padding: 5px;
                        border-radius: 4px;
                        white-space: nowrap;
                        font-size: 12px;
                    }
                `}
            </style>
            {isSidebarVisible && (
                <div className="bg-white text-gray-900 h-screen w-64 p-6 flex flex-col right-shadow transition-transform duration-300 transform relative">
                    <button 
                        className="transition-transform transform hover:scale-105 hover:shadow-xl absolute top-1 right-2 z-50 bg-white p-2 rounded shadow-lg"
                        onClick={() => setSidebarVisible(!isSidebarVisible)}
                    >
                        <ArrowLeftOnRectangleIcon className="w-6 h-6 text-gray-600" />
                    </button>
                    <div className="flex flex-col items-start mt-6 mb-8 tooltip relative">
                        <Link to="/profile" className="flex items-center hover:underline">
                            <img src={user.imageUrl} alt="User's profile" className="h-12 w-12 rounded-full" />
                            <span className="ml-4">{user.name}</span>
                        </Link>
                        <span className="tooltip-text">View/Edit Profile</span>
                        <div className="mt-2 flex items-center">
                            <span className="text-sm text-gray-600 mr-2">Room ID:</span>
                            <span className="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                {user.classroomCode}
                            </span>
                        </div>
                    </div>
                    <div className="mb-6">
                        <SidebarItem title="Classroom" icon={<HomeIcon className="w-6 h-6 text-spring-leaves-800" />} badge={5} />
                        <SidebarItem title="Generate" icon={<PlusCircleIcon className="w-6 h-6 text-spring-leaves-800" />} />
                        <SidebarItem title="Analytics" icon={<DocumentChartBarIcon className="w-6 h-6 text-spring-leaves-800" />} badge={12} />
                        <SidebarItem title="Messages" icon={<ChatBubbleLeftRightIcon className="w-6 h-6 text-spring-leaves-800" />} badge={20} />
                        <SidebarItem title="Documents" icon={<DocumentTextIcon className="w-6 h-6 text-spring-leaves-800" />} />
                        <SidebarItem title="Trending" icon={<FireIcon className="w-6 h-6 text-spring-leaves-800" />} />
                    </div>
                    <div className="mb-4 mt-6 text-sm text-gray-500 uppercase">Your Classrooms</div>
                    <SidebarItem title="Kindergarten" icon={<HomeModernIcon className="w-6 h-6 text-spring-leaves-800" />} />
                    <SidebarItem title="3rd Grade" icon={<HomeModernIcon className="w-6 h-6 text-spring-leaves-800" />} />
                    <SidebarItem title="5th Grade" icon={<HomeModernIcon className="w-6 h-6 text-spring-leaves-800" />} />
                    <div className="mt-auto m-auto mb-4 flex items-center gap-4">
                        <button className="px-4 py-2 bg-spring-leaves-500 text-white rounded">Report</button>
                        <Link to="/some-path" className="text-spring-leaves-500 hover:underline">get help</Link>
                    </div>
                </div>
            )}
            {!isSidebarVisible && (
                <button 
                    className="transition-transform transform hover:scale-105 hover:shadow-xl fixed left-0 top-1/2 -translate-y-1/2 z-50 bg-white p-2 rounded-r shadow-lg"
                    onClick={() => setSidebarVisible(!isSidebarVisible)}
                >
                    <ArrowRightOnRectangleIcon className="w-6 h-6 text-gray-600" />
                </button>
            )}
        </>
    );
};

const SidebarItem: React.FC<{ title: string, icon: JSX.Element, badge?: number }> = ({ title, icon, badge }) => {
    return (
        <Link to={`/${title.toLowerCase()}`} className="flex items-center my-2 hover:bg-spring-leaves-200 hover:text-spring-leaves-500 rounded p-2">
            {icon}
            <span className="ml-4">{title}</span>
            {badge && <span className="ml-2 bg-red-500 text-white text-xs px-2 py-0.5 rounded-full">{badge}</span>}
        </Link>
    );
};

export default Sidebar;
